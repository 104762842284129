import jwtDecode from "jwt-decode";

const EXPIRY_OFFSET_MILLISECONDS = 5000;
const ISSUERS = ["applybuy-checkout-service", "applybuy-shopify-service"];

/**
 * Triggers onExpiry when token is about to expire
 *
 * @param {jwt} token
 * @param {function} onExpiry
 */
const subscribe = ({ token = "", onExpiry = () => {} }) => {
  const { iss = "", exp = 0 } = jwtDecode(token);

  if (exp <= 0 || !ISSUERS.includes(iss)) {
    onExpiry();
    return;
  }

  const expiryTime = new Date(exp * 1000);
  const now = new Date();

  if (expiryTime < now) {
    onExpiry();
    return;
  }

  const milliSecondsToExpire = Math.abs(
    expiryTime - now - EXPIRY_OFFSET_MILLISECONDS
  );

  window.setTimeout(() => {
    onExpiry();
  }, milliSecondsToExpire);
};

export default {
  subscribe,
};
