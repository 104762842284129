import React from "react";
import styled from "styled-components";
import HideOnTablet from "./HideOnTablet";
import ShowOnDevice from "./ShowOnDevice";
import Text from "./Text";
import { BREAKPOINT } from "./theme";

const StyledCellContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  p {
    display: block;
    padding: 5px 0;
  }

  a {
    font-size: 14px;
    margin: 0;
  }
`;

const GridCell = () => {};

const Header = ({ children }) => (
  <HideOnTablet>
    <Text textStyle="body2">
      <strong>{children}</strong>
    </Text>
  </HideOnTablet>
);

const Content = ({ title, children }) => (
  <StyledCellContent>
    <ShowOnDevice tablet>
      <Text textStyle="body2">
        <strong>{title}</strong>
      </Text>
    </ShowOnDevice>

    {children}
  </StyledCellContent>
);

GridCell.Header = Header;
GridCell.Content = Content;

export default GridCell;
