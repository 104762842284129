import React from "react";
import { Flex, LoadingIcon, Text } from "basis";

const LoadingMessage = ({ message = "" }) => (
  <Flex direction="column" height="100vh" placeItems="center">
    <LoadingIcon size="medium" />
    <Text align="center" margin="4 2" textStyle="subtitle2">
      {message}
    </Text>
  </Flex>
);

export default LoadingMessage;
