import React from "react";
import { Message as BasisMessage } from "basis";
import styled from "styled-components";
import { COLOR } from "./theme";

const StyledWrapper = styled.div`
  div:first-child {
    background-color: ${COLOR.YELLOW_LIGHT};
  }
`;

export const Message = ({ bg, ...props }) => {
  // TODO: use basis when "yellow.t10" is supported
  if (bg === "yellow.t10") {
    return (
      <StyledWrapper yellow>
        <BasisMessage {...props} />
      </StyledWrapper>
    );
  }

  return <BasisMessage bg={bg} {...props} />;
};
