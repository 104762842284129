import styled from "styled-components";
import { BREAKPOINT } from "./theme";

const HideOnMobile = styled.div`
  @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    display: none;
  }
`;

export default HideOnMobile;
