import { createQueryString } from "../../../../assets/components/product/utils";
import { LOCATION } from "../../../../assets/constants";
import { generateAboutURL } from "../../../services/url";
import { currencyFormat } from "../../../utils";

import { MONTHLY_FEES } from "./constants";

export const calculateTotals = (amount, currency, promotionMonths) => {
  const orderTotal = Number(amount);
  const monthlyFees = Number(MONTHLY_FEES[currency]);
  const totalFees = Number(promotionMonths) * monthlyFees;
  const monthlyPrice = orderTotal / Number(promotionMonths);
  const totalPrice = orderTotal + totalFees;

  if (isNaN(monthlyFees)) {
    throw new Error("invalid monthlyFees");
  }

  if (isNaN(totalFees)) {
    throw new Error("invalid totalFees");
  }

  if (isNaN(monthlyPrice)) {
    throw new Error("invalid monthlyPrice");
  }

  if (isNaN(totalPrice)) {
    throw new Error("invalid totalPrice");
  }

  return {
    monthlyFees: currencyFormat(monthlyFees),
    totalFees: currencyFormat(totalFees),
    monthlyPrice: currencyFormat(monthlyPrice),
    totalPrice: currencyFormat(totalPrice),
  };
};

// TODO: refactor generateAboutURL() and createQueryString() to be more generic and re-usable
export const generateLearnMoreURL = (
  merchantId,
  amount,
  currency,
  selectedPromotion,
  allowPriceBreakdown
) => {
  const additonalParams = createQueryString(
    selectedPromotion?.type,
    amount,
    selectedPromotion?.promotionMonths,
    allowPriceBreakdown,
    "",
    LOCATION.CHECKOUT
  );
  return generateAboutURL(merchantId, currency, additonalParams);
};
