import React, { useState, useEffect } from "react";
import { Container, Text } from "basis";
import styled, { css } from "styled-components";

import { COLOR } from "./theme";
import { redirect } from "../core/navigate";

const SPACING = "12px 32px";
const BORDER_SIZE = "4px";

const StyledTabContainer = styled.div`
  overflow: hidden;
  display: flex;
  margin-top: 36px;
  border-bottom: 1px solid ${COLOR.LIGHT_GREY};
`;

const StyledTab = styled.button`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: ${SPACING};
  border-bottom: ${BORDER_SIZE} solid transparent;
  transition: 0.3s;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: ${COLOR.LIGHT_SKY_BLUE};
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${COLOR.HIGHLIGHT_BLUE};
    `}
`;

export const Tabs = ({ items = [], settlementTabs = false }) => {
  const [firstItem, secondItem] = items;

  const [selectedTab, setSelectedTab] = useState(firstItem?.id);
  const { ContentComponent } = items.find(({ id }) => selectedTab === id) || {};
  useEffect(()=>{
    if(settlementTabs===false){
      return;
    }
    if(window.location.pathname==='/merchant/statements/settlementsummary'){
      setSelectedTab(firstItem?.id);
    }else{
      setSelectedTab(secondItem?.id)
    }
  }, [])
  return (
    <>
      <StyledTabContainer>
        {items.map((tab, idx) => (
          <StyledTab
            key={idx}
            name={tab?.id}
            selected={selectedTab === tab?.id}
            onClick={settlementTabs?
           ()=>{
              setSelectedTab(tab?.id)
              redirect(tab?.redirectUrl)
               }:
            () => setSelectedTab(tab?.id)}
          >
            <Text textStyle="subtitle2" color="highlight.blue.t100">
              {tab?.title}
            </Text>
          </StyledTab>
        ))}
      </StyledTabContainer>

      {settlementTabs === false && (<Container padding="8 2" hasBreakpointWidth>
        {ContentComponent && <ContentComponent />}
      </Container>)
      }
    </>
  );
};
