import React from "react";
import styled, { css } from "styled-components";

import svg from "./svg";

const StyledContainer = styled.div`
    ${(props) =>
      props.small &&
      css`
        margin-top: -2px;
        width: 24px;
      `}

    ${(props) =>
      props.medium &&
      css`
        width: 30px;
      `}

    ${(props) =>
      props.large &&
      css`
        width: 50px;
      `}

    ${(props) =>
      props.extraLarge &&
      css`
        width: 100px;
      `} 
      
    ${(props) =>
      props.size &&
      css`
        width: ${props.size};
      `}

    ${(props) =>
      props.height &&
      css`
        height: ${props.height};
      `}
    
    ${(props) =>
      props.display &&
      css`
        display: ${props.display};
      `}
      
    ${(props) =>
      props.inline &&
      css`
        display: inline-block;
      `}
      
    ${(props) =>
      props.padding &&
      css`
        padding: 0 4px;
      `}

    ${(props) =>
      props.margin &&
      css`
        margin: ${props.margin};
      `}

    ${(props) =>
      props.center &&
      css`
        align-self: center;
      `}

    ${(props) =>
      props.centerJustified &&
      css`
        text-align: center;
      `}

    ${(props) =>
      props.color &&
      css`
        & {
          --svg-color: ${props.color};
        }
        & > svg > path {
          fill: ${props.color};
        }
      `}

    ${(props) =>
      props.hoverColor &&
      css`
        &:hover {
          & > svg > path {
            fill: ${props.hoverColor};
          }
        }
      `}
`;

export default ({ type, ...restProps }) => {
  if (svg.has(type)) {
    const IconSvg = svg.get(type);
    return (
      <StyledContainer {...restProps}>
        <IconSvg />
      </StyledContainer>
    );
  }

  return null;
};
