import React from "react";
import { BasisProvider, defaultTheme, Message } from "basis";
import styled from "styled-components";

let isIE;

if (typeof window !== "undefined") {
  isIE = /*@cc_on!@*/ false || !!document.documentMode;
}

export const StyledBrowserSupport = styled.div`
  position: relative;
  z-index: 3;

  p {
    strong {
      font-weight: bold;
    }
  }
`;

const BrowserSupport = () => {
  return (
    <StyledBrowserSupport>
      {isIE && (
        <BasisProvider theme={defaultTheme}>
          <Message
            severity="blocking"
            bg="secondary.lightBlue.t25"
            title="Uh oh, looks like your browser (Internet Explorer) is out of date."
            hasBreakpointWidth>
            While we no longer support it, you can install modern alternatives
            for a better browsing experience.
          </Message>
        </BasisProvider>
      )}
    </StyledBrowserSupport>
  );
};

export default BrowserSupport;
