import React from "react";
import { BasisProvider, defaultTheme } from "basis";
import { Helmet } from "react-helmet";
import BrowserSupport from "./components/BrowserSupport";

import { ErrorBoundary, logger, SOURCE } from "./core";

import "./crypto.js";
import "./reset.css";

// initiate logger
logger.init({ source: SOURCE.SPA });

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <Helmet>
      <meta name="referrer" content="origin" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Latitude Partner Hub</title>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:600|Roboto:300,500,700&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://global.oktacdn.com/okta-signin-widget/6.2.0/css/okta-sign-in.min.css"
        type="text/css"
        rel="stylesheet"
      />
    </Helmet>
    <BrowserSupport />

    <BasisProvider theme={defaultTheme}>{element}</BasisProvider>
  </ErrorBoundary>
);
