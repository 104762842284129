import { appUrl } from "../../core/config";
import { BRAND } from "../../core/constants";
import { ERROR_CODES } from "./constants";

const getMinAmountDescription = ({ minimumAmount, currency }) =>
  minimumAmount && currency
    ? `A minimum spend of $${minimumAmount} ${currency} is required to enable Interest Free Payment Plans with this retailer.`
    : "Your cart total is not eligible to enable Interest Free Payment Plans with this retailer.";

export const getErrorDetails = (type, additionalParams) => {
  const brandName = additionalParams?.currency
    ? BRAND[additionalParams?.currency]
    : "";

  const brandMessage =
    brandName !== "" ? `and re-select ${brandName} Interest Free` : "";

  switch (type) {
    case ERROR_CODES.DEFAULT:
      return {
        title: "Something's gone wrong",
        iconType: "emptyPlant",
        description:
          "Oops, something went wrong. We've been notified and looking into this with the utmost urgency.",
      };

    case ERROR_CODES.INVALID_REQUEST:
      return {
        title: "Oops! Something's gone wrong.",
        iconType: "errorApiException",
        description: `Please return to cart ${brandMessage} to try that again.`,
      };

    case ERROR_CODES.INVALID_TOKEN_REQUEST:
      return {
        title: "Oops! Something's gone wrong.",
        iconType: "errorApiException",
        description: `Please return to cart ${brandMessage} to try that again.`,
      };

    case ERROR_CODES.INVALID_MERCHANT:
      return {
        title: "Merchant credentials doesnt look correct.",
        iconType: "emptyPlant",
        description:
          "If you are a customer, please notify this error to merchant.",
        addt:
          "If you are an admin of this store, please verify that correct credentials are used and contact Latitude if problem persists.",
      };

    case ERROR_CODES.INVALID_PROMOTION:
      return {
        title: "No Interest Free plan available",
        iconType: "errorInvalidPromotion",
        description:
          "The items in your cart do not meet the plan requirements. Contact the retailer for more information.",
      };

    case ERROR_CODES.TIME_OUT:
      return {
        title: "Your session has timed out.",
        iconType: "errorTimeOut",
        description: `To complete your transaction, please return to the ${
          additionalParams?.urlCancel !== appUrl
            ? `cart and try again.`
            : `link sent via email or text message.`
        }`,
      };

    case ERROR_CODES.EXCLUDED_ITEMS:
      return {
        title: `Oops! You cannot purchase one or more of your cart items using ${brandName} Interest Free.`,
        iconType: "errorExcludedItems",
        description:
          "Certain products are excluded from payment plans, please contact the retailer for more information.",
      };

    case ERROR_CODES.GIFT_CARD:
      return {
        title: `Oops! You cannot purchase one or more of your cart items using ${brandName} Interest Free.`,
        iconType: "errorNoGiftCard",
        description:
          "Gift Cards are excluded from payment plans, please remove this item & try again.",
      };

    case ERROR_CODES.PAYMENT_APP_ERROR:
      return {
        title: "Oops! Something's gone wrong.",
        iconType: "errorApiException",
        description:
          "If you are an admin of this store, please verify that correct credentials are used and contact Latitude if problem persists.",
      };

    case ERROR_CODES.API_EXCEPTION:
      return {
        title: "Oops! Something's gone wrong.",
        iconType: "errorApiException",
        description: `Please return to cart ${brandMessage} to try that again.`,
      };

    case ERROR_CODES.MIN_AMOUNT:
      return {
        title: `Oops! You cannot purchase one or more of your cart items using ${brandName} Interest Free payment plan.`,
        iconType: "errorLessThanMinimum",
        description: getMinAmountDescription(additionalParams),
      };

    case ERROR_CODES.MAX_AMOUNT:
      return {
        title: `Oops! This purchase exceeds the maximum spend for ${brandName} Interest Free payment plan.`,
        iconType: "errorMoreThanMaximum",
        description: "Please contact the retailer for more information.",
      };

    case ERROR_CODES.UNEXPECTED_ACTIVITY:
      return {
        title: `Unexpected activity`,
        iconType: "errorTimeOut",
        description:
          "We have detected unusual activity on this page. Your session had been recorded and would be closely monitored.",
      };

    case ERROR_CODES.PURCHASE_DISABLED:
      return {
        title: `Sorry! Our system is under maintenance.`,
        iconType: "errorApiException",
        description: "We will be back very soon.",
      };

    case ERROR_CODES.TOKEN_EXPIRED:
      return {
        title: `Oops. It looks like your session expired.`,
        iconType: "errorTimeOut",
        description: "Please return to the app install screen and retry.",
      };

    case ERROR_CODES.INVALID_PURCHASE_CONFIG:
      return {
        title: `Oops! Gateway configuration doesn't look correct.`,
        iconType: "errorApiException",
        description:
          "If you are a customer, please contact the merchant for more information.",
      };

    case ERROR_CODES.INVALID_PURCHASE_REQUEST:
      return {
        title: `Oops! Purchase request doesn't look correct.`,
        iconType: "errorApiException",
        description: `It could be possible that your session has timed out. Please return to cart ${brandMessage} to try again.`,
      };

    case ERROR_CODES.INVALID_PURCHASE:
      return {
        title: `Oops! Something went wrong.`,
        iconType: "errorApiException",
        description:
          "If you are a customer, please contact the merchant for more information.",
      };

    case ERROR_CODES.INVALID_MERCHANT_TOKEN:
      return {
        title: "Your request does not look correct",
        iconType: "errorApiException",
        description:
          "If you are a merchant, please verify that correct credentials are used or contact Latitude if problem persists.",
      };

    case ERROR_CODES.PAYMENT_FAILED:
      return {
        title: "Your payment did not complete successfully.",
        iconType: "errorApiException",
        description:
          "Unstable internet connection could be a possible cause. Please contact the merchant and quote the reference number for more information.",
      };

    case ERROR_CODES.PAYMENT_CANCELLED_AFTER_INITIATE:
      return {
        title: "Payment unsuccessful.",
        iconType: "errorApiException",
        description:
          "This plan has timed out and the purchase did not complete. No funds have been deducted. To complete your purchase, please ask the store to create a new payment link.",
      };

    case ERROR_CODES.PAYMENT_CANCELLED:
      return {
        title: "Payment link unavailable.",
        iconType: "errorApiException",
        description:
          "This payment link may have timed out or may have been cancelled by the store. To complete the purchase, please ask the store to create a new payment link",
      };

    case ERROR_CODES.DEFAULT:
    default:
      return {
        title: "Something's gone wrong",
        iconType: "emptyPlant",
        description:
          "Oops, something went wrong. We've been notified and looking into this with the utmost urgency.",
      };
  }
};
