import React from "react";
import { BREAKPOINT } from "./theme";
import styled, { css } from "styled-components";

const ShowOnDevice = styled.div`
  ${(props) =>
    props.mobile &&
    css`
      @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
        display: none;
      }
    `}
  ${(props) =>
    props.tablet &&
    css`
      @media (min-width: ${BREAKPOINT.SIZE_TABLET}) {
        display: none;
      }
    `}
`;

export default ({ tablet, mobile, children }) => {
  return (
    <ShowOnDevice tablet={tablet} mobile={mobile}>
      {children}
    </ShowOnDevice>
  );
};
