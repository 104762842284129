import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Container, Text } from "basis";

import { COLOR } from "./theme";

const StyledTextArea = styled.textarea`
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${COLOR.BLACK};
  height: 48px;
  padding-left: 16px;
  padding-right: 40px;
  margin: 0;
  border: 0;
  border-radius: 0;
  appearance: none;
  align-self: flex-start;
  background-color: ${COLOR.WHITE};
  width: 80%;
  max-width: 80vw;
  min-height: 300px;
  resize: vertical;

  ${(props) =>
    props.error &&
    css`
      border-bottom: 4px solid ${COLOR.RED};
    `}
`;

const Textarea = ({
  name,
  placeholder,
  label,
  value,
  disabled,
  onChange = () => {},
  validate = () => null,
}) => {
  const [state, setState] = useState({ value: "", error: false });

  const handleChange = (e) => {
    const value = e.target?.value;
    const error = validate(value);

    setState({ value, error });
    onChange({ value, error });
  };

  return (
    <>
      <Text color="grey.t75">
        <strong>{label}</strong>
      </Text>

      <Container margin="3 0">
        <StyledTextArea
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          error={state.error}
        />

        {state.error && (
          <Text textStyle="body2" color="conditional.negative.text">
            {state.error}
          </Text>
        )}
      </Container>
    </>
  );
};

export default Textarea;
