import React from "react";
import styled from "styled-components";
import { LoadingIcon } from "basis";

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0 0 0;
`;

const Loading = () => (
  <div>
    <Wrapper>
      <LoadingIcon />
    </Wrapper>
  </div>
);

export default Loading;
