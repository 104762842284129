import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Stack, Text } from "basis";

import { COLOR } from "./theme";

const StyledInput = styled.input`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${COLOR.DARK_GREY};
  height: 48px;
  margin: 0;
  border-style: solid;
  border-color: ${COLOR.LIGHT_GREY};
  border-radius: 5px;
  appearance: none;
  outline: none;
  padding-left: 16px;
  font-weight: 200;

  &:focus {
    border-color: ${COLOR.HIGHLIGHT_BLUE};
  }

  ${(props) =>
    props.error &&
    css`
      border-bottom: 4px solid ${COLOR.RED};
    `}
`;

export const Input = ({
  name,
  placeholder,
  label,
  value,
  disabled,
  onChange = () => {},
  validate = () => null,
  ...props
}) => {
  const [state, setState] = useState({ value: "", error: false });

  const handleChange = (e) => {
    const value = e.target?.value;
    const error = validate(value);

    setState({ value, error });
    onChange({ value, error });
  };

  useEffect(() =>{
    const error = validate(value);
    setState({ value, error });
  }, [value])

  return (
    <Stack>
      <p>{label}</p>

      <StyledInput
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
        error={state.error}
        {...props}
      />

      {state.error && (
        <Text textStyle="body2" color="conditional.negative.text">
          {state.error}
        </Text>
      )}
    </Stack>
  );
};
