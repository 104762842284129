import { CURRENCY } from "../../../src/core/constants";
import {
  ALLOWED_PROMOTION_LENGTH,
  PAYMENT_OPTIONS,
  EVENT_HANDLERS,
  INVERSED_LAYOUTS,
  CUSTOM_DETAILS
} from "../../constants";
import querystring from "querystring";
import { FEES } from "../../../src/components/Promotions/Description/constants";

export const getInstallment = (price = 0, promotionMonths = 0) => {
  if (parseFloat(price) <= 0 || parseInt(promotionMonths) <= 0) {
    return { amount: 0, length: 0 };
  }

  let length = parseInt(promotionMonths);

  let monthly = parseFloat(price) / parseInt(promotionMonths);
  let amount = monthly.toFixed(2);

  return { amount, length };
};

export const isPromotionLengthValid = (length = 0) =>
  ALLOWED_PROMOTION_LENGTH.includes(Number(length));

export const shouldShowPriceBreakdown = ({
  price,
  minAmountOverride,
  minAmount,
  paymentOption,
  priceThreshold,
  currency,
}) => {
  const priceThresholdAmount = priceThreshold?.priceThreshold;

  if (price < minAmount) {
    console.info(
      `price breakdown not rendered as price is under $${minAmount}`
    );
    return { allowPriceBreakdown: false, defaultWidget: true };
  }
  if (price < minAmountOverride) {
    console.info(
      `price breakdown not rendered as price is under $${minAmountOverride}`
    );
    return { allowPriceBreakdown: false, defaultWidget: true };
  }
  if (
    currency === CURRENCY.NZD &&
    priceThresholdAmount !== undefined &&
    paymentOption === PAYMENT_OPTIONS.EQUAL &&
    price > priceThresholdAmount
  ) {
    console.info(
      `price breakdown not rendered as price $${price} is more than plan threshold $${priceThresholdAmount}`
    );
    return { allowPriceBreakdown: false, defaultWidget: true };
  }

  if (paymentOption !== PAYMENT_OPTIONS.EQUAL) {
    return { allowPriceBreakdown: false };
  }

  return { allowPriceBreakdown: true, defaultWidget: false };
};

export const reviseWidgetSettings = ({
    paymentOption,
    minAmount,
    price,
    allowPriceBreakdown,
    defaultWidget
}) => {
  //if payment option is not equal, show default widget
  let revised = { allowPriceBreakdown, defaultWidget };
  if (paymentOption !== PAYMENT_OPTIONS.EQUAL){
    revised.allowPriceBreakdown = false;
    revised.defaultWidget = true;
  }

  //if equal and minAmount exists, it supersed the original allowPriceBreakdown value
  if (paymentOption === PAYMENT_OPTIONS.EQUAL && minAmount) {
    revised.allowPriceBreakdown = price >= minAmount;
    revised.defaultWidget = !revised.allowPriceBreakdown;
  }

  return revised;
}

const calculatePoint = (price, merchantId, currency) => {
  if (CUSTOM_DETAILS[merchantId]) {
    return Math.round(price * CUSTOM_DETAILS[merchantId].burnRate);
  }

  return Math.round(price * CUSTOM_DETAILS[currency].burnRate);
};

const getMerchantName = (merchantId, currency) => {
  if (CUSTOM_DETAILS[merchantId]) {
    return CUSTOM_DETAILS[merchantId].merchantName;
  }
  
  return CUSTOM_DETAILS[currency].merchantName;
};

export const getTitles = ({
  length,
  amount,
  aboutURL,
  price,
  currency,
  defaultWidget,
  layout,
  merchantId
}) => {
  return {
    FALLBACK_TITLE: `${
      defaultWidget
        ? INVERSED_LAYOUTS.includes(layout)
        ? `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available now.</p>`
        : `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available with</p>`
        : currency === CURRENCY.AUD
        ? `<p style="margin:0; line-height: 1.6; color: inherit;"><strong>Interest Free</strong> over ${length} months.</p>`
        : `<p style="margin:0; line-height: 1.6; color: inherit;">${length} Months <strong>Gem Interest Free</strong></p>`
    }`,
    ONELINE_TITLE: INVERSED_LAYOUTS.includes(layout) ? 
        `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available now.</p>`
        : `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available with</p>`,
    POINTS_TITLE: `<div style="max-width: 70%">
                  <p style="margin:0; line-height: 1.6; color: inherit;">
                    <strong>Shop with ${calculatePoint(price, merchantId, currency)} ${getMerchantName(merchantId, currency)} Points</strong>
                  </p>
                  <p style="margin:0; line-height: 1.6; color: inherit;">
                    Don't have a ${getMerchantName(merchantId, currency)} credit card? <u id="lat-find-out" style="cursor: pointer">Find out more</u>
                  </p>
              </div>`,
    LEGACY_TITLE: 
        INVERSED_LAYOUTS.includes(layout) 
        ? `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available now.</p>`
        : `<p style="margin:0; line-height: 1.6; color: inherit;">Long term payment plans available with</p>`,
    PRICE_BREAKDOWN_TITLE: `${
      currency === CURRENCY.AUD
        ? `<p style="display:inline; margin:0 0 5px 0; line-height: 1.2; color: inherit;"><strong>Interest Free</strong> $${amount} per month for ${length} months. (Terms, conditions & fees apply)<span style="margin-left: 5px;white-space:nowrap;"><a style="text-decoration:underline;cursor:pointer;color:inherit;" target="_blank" id="lat-find-out" onclick="${EVENT_HANDLERS.LEARN_MORE_CLICK}('${aboutURL}')">Learn more</a></span></p>`
        : `<p style="display:inline; margin:0 0 5px; line-height: 1.2; color: inherit;"><strong>Interest Free</strong> $${amount} per month for ${length} months. (Total repayments $${price}, excluding a one-off Gem Visa $${FEES.ESTABLISHMENT} establishment fee & $${FEES.GEM_VISA} annual fees - (charged $${FEES.GEM_VISA_HALF_YEARLY} half yearly (Gem Visa/CreditLine)).<span style="margin-left: 5px;white-space:nowrap;"><a id="lat-find-out" style="text-decoration:underline;cursor:pointer;color:inherit;" target="_blank" onclick="${EVENT_HANDLERS.LEARN_MORE_CLICK}('${aboutURL}')">Learn more</a></span></p>`
    }`,
  };
};


// TODO: refactor this
export const createQueryString = (
  paymentOption,
  price,
  promotionMonths,
  allowPriceBreakdown,
  productName,
  location
) => {
  const urlParams = {
    paymentOption,
    price: (price * 100).toFixed(0),
    promotionMonths,
    productName,
    ppm: allowPriceBreakdown ? 1 : 0,
    location,
  };

  const queryString = querystring.stringify(urlParams, "&", "=");
  const encodedURI = queryString.replace(/[!'()*]/g, escape);

  return encodedURI;
};