import styled, { css } from "styled-components";
import { BREAKPOINT, COLOR } from "./theme";

// TODO: move spacing and colors to constants
const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}

  ${(props) =>
    props.border &&
    css`
      &:not(:last-child) {
        border-bottom: 3px solid ${COLOR.WHITE};
      }
    `}
  
  ${(props) =>
    props.blue &&
    css`
      background-color: ${COLOR.SECONDARY_LIGHT_BLUE_25};
    `}

  ${(props) =>
    props.lightBlue &&
    css`
      background-color: ${COLOR.LIGHT_BLUE};
    `}

  ${(props) =>
    props.grey &&
    css`
      background-color: ${COLOR.GREY};
    `}
  
  ${(props) =>
    props.mobileGutter &&
    css`
      @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
        margin-left: 20px;
        margin-right: 20px;
      }
    `}

  ${(props) =>
    props.alignContent &&
    css`
      align-items: ${props.alignContent};
    `}

  ${(props) =>
    props.alignBottom &&
    css`
      align-items: baseline;
    `}

  ${(props) =>
    props.gap &&
    css`
      column-gap: ${props.gap};
      `}

  ${(props) =>
    props.responsive &&
    css`
      @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
        flex-direction: column;
      }

      & > *,
      & > button {
        margin: 6px 0;
      }
    `}
  
    & > a {
      flex-shrink: 0; 
      max-height: 20px;
    }
`;

export default StyledFlex;
