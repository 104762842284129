import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon/Icon";
import { COLOR } from "./theme";

const BUTTON_SIZE = "48px";
const ICON_SIZE = "22px";

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: ${COLOR.GREY};
  position: relative;
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background: transparent;

  ${(props) =>
    props.padding &&
    css`
      margin: ${props.padding};
    `}
  width: calc(100% - 30px);
  height: ${BUTTON_SIZE};
`;

const StyledButton = styled.button`
  position: absolute;
  cursor: pointer;
  background: ${COLOR.GREY};
  outline: none;
  border: none;
  min-width: ${BUTTON_SIZE};
  min-height: ${BUTTON_SIZE};
  right: 0;
  top: 0;
`;

export const SearchInput = ({
  placeholder,
  searchText = "",
  validateFn = () => {},
  onSearch = () => {},
  padding = "0px 15px 0px 15px"
}) => {
  const inputReference = useRef(null);

  const [value, setValue] = useState(searchText);
  const isSearched = searchText != "" && searchText === value;

  const handleClear = () => {
    setValue("");
    onSearch("");
  };

  const handleChange = (evt) => {
    const newValue = evt?.target?.value;

    if (!validateFn(newValue)) {
      return;
    }

    setValue(newValue);
  };

  useEffect(() => {
    inputReference.current.focus();
  }, []);

  return (
    <StyledWrapper>
      <StyledInput
        ref={inputReference}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        padding={padding}
      />

      <StyledButton>
        {!isSearched && (
          <Icon
            type="iconSearch"
            size={ICON_SIZE}
            color={COLOR.HIGHLIGHT_BLUE}
            hoverColor={COLOR.LATITUDE_BLUE}
            onClick={() => onSearch(value)}
          />
        )}

        {isSearched && (
          <Icon
            type="iconClose"
            size={ICON_SIZE}
            color={COLOR.HIGHLIGHT_BLUE}
            hoverColor={COLOR.LATITUDE_BLUE}
            onClick={handleClear}
          />
        )}
      </StyledButton>
    </StyledWrapper>
  );
};
