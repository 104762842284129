export const COLOR = Object.freeze({
  SECONDARY_LIGHT_BLUE_15: "#EFF7FF",
  SECONDARY_LIGHT_BLUE_25: "#d8edff",
  SECONDARY_LIGHT_BLUE_80: "#82c6ff",
  LIGHT_BLUE: "#eaf5ff",
  LATITUDE_BLUE: "#0046AA",
  LINK_BLUE: "#0061ee",
  HIGHLIGHT_BLUE: "#006aff",
  DARK_SKY_BLUE: "#1E4E6C",
  LIGHT_SKY_BLUE: "#E8F4FF",
  RED: "#cf000f",
  DARK_RED: "#ca1010",
  RED_LIGHT: "#FAE6E7",
  LIGHT_GREY: "#e1e1e1",
  GREY: "#f2f2f2",
  MID_GREY: "#b2b2b2",
  DARK_GREY: "#414141",
  GREY_50: "#999",
  GREY_10: "#f8f8f8",
  BLACK: "#000000",
  BLACK_50:"#7F7F7F",
  WHITE: "#FFFFFF",
  YELLOW_LIGHT: "#FEF8EC",
  GREEN: "#1A8450",
  GREEN_LIGHT: "#DEF8E5",
  ATTENTION: "#F9B845",
  ATTENTION_LIGHT: "#FFF5E1",
  PURPLE: "#7A65D4",
  PURPLE_LIGHT: "#E5E4FC",
  TURQUOISE: "#0FDBF3",
  TURQUOISE_LIGHT: "#E7FBFD",
  SECONDARY_PURPLE_LIGHT: "#AAA7F0",
  SECONDARY_PURPLE_DARK: "#2D3472",
  VOID_BLUE_DARK: "#5E89EE",
  VOID_BLUE_LIGHT: "#E9F0FD",
  GRAY_DARK: "#595959",
  GRAY_LIGHT: "#E5E5E5",
  INFO: "#c31b6c",
});

export const BREAKPOINT = Object.freeze({
  SIZE_WIDE_DESKTOP: "1480px",
  SIZE_DESKTOP: "1280px",
  SIZE_TABLET: "1024px",
  SIZE_MOBILE: "768px",
});
