

const date = new Date();

export const localDate = date.toLocaleString("default", {
  day: "numeric",
  month: "long",
  year: "numeric",
});

export const utcTimeDate = (date) => {
  const newTransactionDate = new Date(date).toLocaleString("en-au", {
    hour12: true,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return newTransactionDate.replace(",", "");
};

export const utcDate = (date) => {
  const newTransactionDate = new Date(date).toLocaleString("en-au", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return newTransactionDate.replace(",", "");
};

export const getDateXDaysAgo = (numOfDays, date = new Date()) => {
  const daysAgo = new Date(date.getTime());

  daysAgo.setDate(date.getDate() - numOfDays);
  daysAgo.setHours(0, 0, 0, 0);

  return daysAgo;
};

export const toDateString = (date = new Date()) => {
  let dayOfMonth = Number(date?.getDate());
  let month = Number(date?.getMonth()) + 1;
  let year = Number(date?.getFullYear());

  if (dayOfMonth < 10) {
    dayOfMonth = `0${dayOfMonth}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}-${month}-${dayOfMonth}`;
};

export const toFriendlyDate = (date = new Date()) =>
  date
    .toLocaleString("en-au", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    ?.replace(",", "") ?? "";

export const timezone =
  new Date()
    .toLocaleTimeString("en-au", { timeZoneName: "short" })
    ?.split(" ")
    ?.slice(-1)[0] ?? "";

export const toGraphFriendlyDate = (dateString) => {
  return new Date(dateString.split("T")[0]).toLocaleDateString('en-AU', { day: 'numeric', month: 'short' })
}
export const convertToGMT = (inputDate) => {
  const inputDateTime = new Date(inputDate);

  // Get the user's local timezone offset in milliseconds
  const offsetMinutes = new Date().getTimezoneOffset();
console.log(offsetMinutes)
  // Adjust the input date by the offset to get GMT
  const gmtDateTime = new Date(inputDateTime.getTime() - offsetMinutes);

  // Format the GMT DateTime as a string without milliseconds
  const gmtDateString = gmtDateTime.toISOString()
  const gmtDate = gmtDateString.split('.')
  return gmtDate[0] + 'Z';
}