import React, { Component } from "react";
import { Container } from "basis";

import { ErrorMessage } from "../components";
import { logger } from "./logger";

export class ErrorBoundary extends Component {
  state = { hasError: false, error: null, errorInfo: null };

  static getDerivedStateFromError(error) {
    return { error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(`Uncaught ${error}`, errorInfo);
  }

  render() {
    const { FallbackComponent } = this.props;

    const { hasError, error } = this.state;

    if (hasError) {
      const props = {
        error,
      };

      //  render fallback component when provided
      if (FallbackComponent) {
        return <FallbackComponent {...props} />;
      }

      // or render default error container
      return (
        <Container padding="6 8" hasBreakpointWidth>
          <ErrorMessage />
        </Container>
      );
    }

    return this.props.children;
  }
}
