import { CURRENCY } from "../../assets/constants";
import { appUrl, checkoutServiceBaseUrl } from "../core/config";

export const MERCHANT_ID_FALLBACK_VALUE = "merchantId";

export const URL_TYPE = {
  APPLY: "apply",
  TERMS: "terms",
  LEARN_MORE: "learnmore",
};

export const LOCATION = {
  ABOUT_PAGE: "learn-more-modal",
  CHECKOUT_PAGE: "checkout",
  MERCHANT_CHECKOUT_PAGE: "merch-checkout",
};

const ABOUT_ROUTE = {
  [CURRENCY.AUD]: "/about/au",
  [CURRENCY.NZD]: "/about/nz",
};

const ADDT_PARAMS = {
  [URL_TYPE.APPLY]: {
    [LOCATION.ABOUT_PAGE]: "&channel=Online&source=0&stream=Upstream",
    [LOCATION.CHECKOUT_PAGE]: "&channel=Online&source=3&stream=Downstream",
    [LOCATION.MERCHANT_CHECKOUT_PAGE]:
      "&channel=Online&source=0&stream=Upstream",
  },
};

export const generateURL = (
  merchantId = "",
  country = "",
  type = "",
  location = ""
) => {
  // fallback to learn_more when type is invalid
  if (!Object.values(URL_TYPE).includes(type)) {
    type = URL_TYPE.LEARN_MORE;
  }

  // fallback to latitude checkout when location is invalid
  if (!Object.values(LOCATION).includes(location)) {
    location = LOCATION.CHECKOUT_PAGE;
  }

  if (!merchantId || merchantId.length < 1) {
    merchantId = MERCHANT_ID_FALLBACK_VALUE;
  }

  let additionalParams = "";
  if (ADDT_PARAMS[type]) {
    additionalParams = ADDT_PARAMS[type][location] || "";
  }

  if (!country || country.length < 1) {
    return `${checkoutServiceBaseUrl}/redirect/${merchantId}/url/${type}?location=${location}${additionalParams}`;
  }

  return `${checkoutServiceBaseUrl}/redirect/${merchantId}/url/${type}?location=${location}&country=${country}${additionalParams}`;
};

// TODO: refactor this
export const generateAboutURL = (
  merchantId = "",
  currency = CURRENCY.AUD,
  queryString = ""
) => {
  if (!merchantId || merchantId.length < 1) {
    merchantId = MERCHANT_ID_FALLBACK_VALUE;
  }

  if (!Object.values(CURRENCY).includes(currency)) {
    currency = CURRENCY.AUD;
  }

  return `${appUrl}${ABOUT_ROUTE[currency]}?merchantId=${merchantId}&${queryString}`;
};
