import styled, { css } from "styled-components";
import { COLOR } from "./theme";

export const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.HIGHLIGHT_BLUE};
    outline: 1px solid ${COLOR.LIGHT_BLUE};
  }

  ${(props) =>
    props.height &&
    css`
      max-height: ${props.height};
    `}
`;
