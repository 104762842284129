import styled from "styled-components";
import { BREAKPOINT } from "./theme";

const HideOnTablet = styled.div`
  @media screen and (max-width: ${BREAKPOINT.SIZE_TABLET}) {
    display: none;
  }
`;

export default HideOnTablet;
