import React from "react";
import { Text } from "basis";

import Link from "../../Link";
import { FEES } from "./constants";
import { calculateTotals } from "./utils";

export const Equal = ({
  onLearnMoreClick = () => {},
  promotion,
  amount,
  currency,
  learnMoreURL,
  au,
  nz,
}) => {
  const { promotionMonths } = promotion;

  const { monthlyFees, monthlyPrice, totalPrice } = calculateTotals(
    amount,
    currency,
    promotionMonths
  );

  const AU = () => (
    <>
      <Text textStyle="body2">
        <strong>
          Pay ${monthlyPrice} per month for {promotionMonths} months
        </strong>{" "}
        (excludes monthly account fee ${monthlyFees}). Terms, conditions and
        fees apply.{" "}
        <Link
          href={learnMoreURL}
          onClick={onLearnMoreClick}
          newTab
          smallFont
          inline
        >
          Learn more
        </Link>
      </Text>

      <Text textStyle="body2" margin="3 0">
        You need to pay the purchase amount in equal monthly instalments over
        the term.
      </Text>
    </>
  );

  const NZ = () => (
    <>
      <Text textStyle="body2" margin="0 0 3">
        <strong>
          Pay ${monthlyPrice} per month for {promotionMonths} months.
        </strong>
      </Text>

      <Text textStyle="body2" margin="0 0 3">
        Total repayment amount is <strong>${totalPrice}</strong> (Excludes a
        one-off ${FEES.ESTABLISHMENT} establishment fee and ${FEES.GEM_VISA}{" "}
        annual fees - (charged ${FEES.GEM_VISA_HALF_YEARLY} half yearly (Gem
        Visa/CreditLine)).
      </Text>

      <Link href={learnMoreURL} onClick={onLearnMoreClick} newTab smallFont>
        Learn more on Short Term Instalment Interest Free
      </Link>
    </>
  );

  return (
    <>
      {au && <AU />}
      {nz && <NZ />}
    </>
  );
};
