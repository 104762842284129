import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "basis";

import { COLOR } from "./theme";
import { Flex } from ".";

const THUMB_SIZE = "20px";
const RAIL_SIZE = "6px";

const track = css`
  appearance: none;
  background: transparent;
  border: transparent;
`;

const thumb = css`
  appearance: none;
  pointer-events: all;
  width: ${THUMB_SIZE};
  height: ${THUMB_SIZE};
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: ${COLOR.HIGHLIGHT_BLUE};

  &:active {
    cursor: grabbing;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 calc(${THUMB_SIZE} / 2);
  height: calc(${THUMB_SIZE} + 1.6rem);
`;

const StyledInputWrapper = styled.div`
  width: calc(100% + ${THUMB_SIZE});
  margin: 0 calc(${THUMB_SIZE} / -2);
  position: absolute;
  height: ${THUMB_SIZE};
`;

const StyledControlWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: ${THUMB_SIZE};
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  // These vendor prefix exceptions must be kept seperate for slider to work on all browsers
  &::-ms-track {
    ${track}
  }

  &::-moz-range-track {
    ${track}
  }

  &:focus::-webkit-slider-runnable-track {
    ${track}
  }

  &::-ms-thumb {
    ${thumb}
  }

  &::-moz-range-thumb {
    ${thumb}
  }

  &::-webkit-slider-thumb {
    ${thumb}
  }
`;

const StyledRail = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: ${RAIL_SIZE};
  border-radius: calc(${RAIL_SIZE} / 2);
  background: ${COLOR.LIGHT_GREY};
`;

const StyledInnerRail = styled.div`
  position: absolute;
  height: 100%;
  background: ${COLOR.HIGHLIGHT_BLUE};
  opacity: 0.5;
`;

const StyledControl = styled.div`
  width: ${THUMB_SIZE};
  height: ${THUMB_SIZE};
  border-radius: 50%;
  position: absolute;
  background: ${COLOR.HIGHLIGHT_BLUE};
  top: 50%;
  margin-left: calc(${THUMB_SIZE} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
`;

export const RangeSlider = ({ min = 0, max, disabled = false, value, step, onChange }) => {
  const handleSelectionChange = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const newMaxVal = Math.max(+e.target.value, min);
    onChange(newMaxVal);
  };

  const startPos = min === 0 ? (min / (max - min)) * 100 : 0;
  const endPos = value > 0 ? ((value - min) / (max - min)) * 100 : 0;

  const isNearStart = Math.floor(value - min) <= step * 2;
  const isNearEnd = Math.floor(max - value) <= step * 2;
  const currStep = isNearStart || isNearEnd ? 1 : step;

  return (
    <StyledWrapper>
      <StyledInputWrapper>
        <StyledInput
          type="range"
          value={value}
          min={min}
          max={max}
          step={currStep}
          onChange={handleSelectionChange}
        />
      </StyledInputWrapper>

      <StyledControlWrapper>
        <StyledRail>
          <StyledInnerRail
            style={{ left: `${startPos}%`, right: `${100 - endPos}%` }}
          />
        </StyledRail>

        <StyledControl style={{ left: `${endPos}%` }} />

        <Flex padding="24px 0 0">
          <Text textStyle="legal" color="grey.t75">
            {min} pts
          </Text>
          <Text textStyle="legal" color="grey.t75">
            {max} pts
          </Text>
        </Flex>
      </StyledControlWrapper>
    </StyledWrapper>
  );
};
