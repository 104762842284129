export const MODAL_CLASSES = {
    container: "latitude-modal",
    showContainer: "latitude-show-modal",
    content: "latitude-modal-content",
    title: "latitude-title",
    titleFont: "latitude-title-font",
    body: "latitude-modal-inner",
    description: "latitude-subtitle",
    standardText: "latitude-standard-text",
    bold: "latitude-bold",

    headerContainer: "latitude-header",
    customLogo: "latitude-logo",
    logoHead: "latitude-header-logo",
    closeButton: "latitude-close-button",

    productContainer: "latitude-product-list",
    product: "latitude-product",
    productDetails: "latitude-product-details",
    productInner: "latitude-product-inner",
    productLogo: "latitude-product-logo",
    productTitle: "latitude-product-title",
    productFeatures: "latitude-features",
    productInnerFeatures: "latitude-inner-features",
    featureBullet: "latitude-feature-bullet",
    buttonContainer: "latitude-button-container",
    productLearn: "latitude-learn",
    productApply: "latitude-apply",
    applyIcon: "latitude-apply-icon",

    compare: "latitude-compare",
    footerText: "latitude-text",
}