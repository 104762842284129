import React, { useEffect, useRef, useState } from "react";
import { Icon } from "basis";
import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const SPACING = "12px";
const POPOVER_SIZE = "200px";
const POPOVER_SHADOW =
  "rgba(39, 44, 48, 0.05) 0px 0px 0px 1px, rgba(39, 44, 48, 0.16) 0px 2px 7px 1px";

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  padding; ${SPACING};
`;

const StyledTitleWrapper = styled.div`
  cursor: pointer;
  min-width: ${POPOVER_SIZE};
  display: flex;
  justify-content: end;
  align-items: center;
  padding: ${SPACING} 0;

  &:hover {
    background: ${COLOR.LIGHT_GREY};
  }

  ${(props) =>
    props.active &&
    css`
      background: ${COLOR.LIGHT_GREY};
    `}
`;

const StyledContent = styled.div`
  position: absolute;
  background-color: ${COLOR.WHITE};
  min-width: ${POPOVER_SIZE};
  box-shadow: ${POPOVER_SHADOW};
  padding: ${SPACING} 0;
  z-index: 1;
  right: 0;
  ${(props) =>
    props.show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export const Popover = ({ children, ContentComponent, ...props }) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // hide when clicked outside
      if (ref.current && !ref.current.contains(event.target)) {
        isVisible && setVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isVisible]);

  return (
    <StyledContainer ref={ref}>
      <StyledTitleWrapper
        data-testid="popover"
        active={isVisible}
        onClick={() => setVisible(!isVisible)}
      >
        {children}
        <Icon name={isVisible ? "chevron-up" : "chevron-down"} color="highlight.blue.t100" />
      </StyledTitleWrapper>

      <StyledContent show={isVisible}>
        <ContentComponent data-testid="popover-content" {...props} />
      </StyledContent>
    </StyledContainer>
  );
};
