import React, { useState } from "react";
import styled from "styled-components";
import { COLOR } from "./theme";
import { Icon } from ".";
import { Text } from "basis";

const StyledSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;

  h6 {
    width: 100%;
  }

  form {
    background: ${COLOR.GREY};
    width: 100%;
    position: relative;
  }

  label {
    width: 100%;
    display: block;
  }

  input {
    outline: none;
    border: none;
    background: transparent;
    padding: 15px;
    width: calc(100% - 30px);
    height: 18px;
  }

  button.search {
    position: absolute;
    cursor: pointer;
    background: ${COLOR.GREY};
    outline: none;
    border: none;
    min-width: 48px;
    min-height: 48px;
    right: 0;
    top: 0;

    & div {
      margin: 0 auto;
    }
  }

  button.clear-search {
    background: ${COLOR.WHITE};
    border: none;
    min-width: 48px;
    text-decoration: underline;
    white-space: nowrap;
    padding-left: 10px;
    font-size: 12px;
    color: ${COLOR.DARK_GREY};
    border-bottom-color: ${COLOR.DARK_GREY};
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    border-bottom-width: 1px;
  }
`;

const Search = ({
  loading,
  placeholderText,
  setCurrentPage,
  setStart,
  setSearchTerm,
}) => {
  const [clearButton, showClearButton] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [errorActive, setErrorActive] = useState(false);
  const [term, setTerm] = useState();

  const searchButton = (e) => {
    e.preventDefault();

    if (term === undefined) {
      setErrorActive(true);
      return;
    }

    setSearchTerm(term);
    setCurrentPage(1);
    setStart(0);
    showClearButton(true);
  };

  const clearSearch = () => {
    document.getElementById("search-form").value = "";
    setSearchTerm("");
    setCurrentPage(1);
    showClearButton(false);
    setSearchActive(false);
  };

  const searchInteraction = (value) => {
    setTerm(value);
    setErrorActive(false);
  };

  return (
    <StyledSearch>
      <form onSubmit={searchButton}>
        <label htmlFor="search-form">
          <input
            disabled={loading}
            name="search-form"
            id="search-form"
            className={`${searchActive} search-input"`}
            placeholder={placeholderText}
            onChange={(e) => searchInteraction(e.target.value)}
            onClick={() => setSearchActive(true)}
          />
        </label>
        <button className={`${searchActive} search`} onClick={searchButton}>
          <Icon type="iconSearch" size="20px" color={COLOR.HIGHLIGHT_BLUE} />
        </button>
      </form>
      {clearButton && (
        <button className="clear-search" onClick={clearSearch}>
          Clear search
        </button>
      )}
      {errorActive && (
        <Text as="h6" color="conditional.negative.text" margin="3 0 0">
          Please enter search term
        </Text>
      )}
    </StyledSearch>
  );
};

export default Search;
