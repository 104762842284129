import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { Container, Message } from "basis";

const animation = keyframes`
  0% { transform: translateY(-20%) }
  100% { transform: translateY(0%) }		
`;

const animationRule = css`
  ${animation} 300ms ease-in-out;
`;

const CHECK_INTERVAL = 5000;

const StyledWrapper = styled.div`
  animation: ${animationRule};
`;

const checkStatus = async () => {
  try {
    /* 
      navigator.onLine returns true when the device is connected to a network but
      that doesn't mean its also connected to the internet 
      which are 2 very different things. 
     */
    if (!window.navigator.onLine) return false;

    // make sure decvice is connected to the internet
    const { status } = await fetch(window.location.origin, { method: "HEAD" });

    return status >= 200 && status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

export const OnlineStatus = ({ onChange = () => {} }) => {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    const interval = setInterval(async () => {
      const newOnline = await checkStatus();

      if (online === newOnline) {
        return;
      }

      setOnline(newOnline);
      onChange(online);
    }, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [online, onChange]);

  if (online) {
    return null;
  }

  return (
    <Container margin="2 0 4 0">
      <StyledWrapper>
        <Message
          title="Unstable internet connection"
          severity="warning-or-significant"
          bg="secondary.pink.t30"
        >
          It appears you have an unstable internet connection right now. Please
          refrain from making the payment until this alert is no longer
          displayed.
        </Message>
      </StyledWrapper>
    </Container>
  );
};
