export const ERROR_CODES = {
  DEFAULT: "default",
  INVALID_REQUEST: "invalid-request",
  INVALID_TOKEN_REQUEST: "invalid-token-request",
  INVALID_MERCHANT: "invalid-merchant",
  INVALID_PROMOTION: "invalid-promotion",
  TIME_OUT: "time-out",
  EXCLUDED_ITEMS: "excluded-items",
  GIFT_CARD: "gift-card",
  PAYMENT_APP_ERROR: "payment-app-error",
  MIN_AMOUNT: "less-than-minimum-purchase-amount",
  MAX_AMOUNT: "more-than-maximum-purchase-amount",
  UNEXPECTED_ACTIVITY: "unexpected-activity",
  PURCHASE_DISABLED: "purchase-disabled",
  API_EXCEPTION: "api-exception",
  TOKEN_EXPIRED: "token-expired",
  INVALID_PURCHASE_CONFIG: "invalid-purchase-config",
  INVALID_PURCHASE_REQUEST: "invalid-purchase-request",
  INVALID_PURCHASE: "invalid-purchase",
  INVALID_MERCHANT_TOKEN: "invalid-merchant-token",
  PAYMENT_FAILED: "payment-failed",
  PAYMENT_CANCELLED: "payment-cancelled",
  PAYMENT_CANCELLED_AFTER_INITIATE: "payment-cancelled-after-initiate",
};
