import React from "react";
import { Flex, Text } from "basis";
import styled, { css } from "styled-components";
import { COLOR } from "../theme";

import CheckboxSvg from "./checkbox.svg";

const BORDER_RADIUS_SIZE = "5px";
const BORDER_SIZE = "0.5px";
const BOX_SIZE = "24px";
const SPACING = "12px";

const CheckboxIcon = styled((props) => <CheckboxSvg {...props} />)`
  cursor: pointer;
  border-radius: ${BORDER_RADIUS_SIZE};
  border: solid ${BORDER_SIZE} ${COLOR.MID_GREY};

  height: ${BOX_SIZE};
  margin-right: ${SPACING};

  & rect {
    fill: ${COLOR.WHITE};
  }

  ${(props) =>
    props.selected &&
    css`
      & path[data-id="inner-tick"] {
        stroke: ${COLOR.LATITUDE_BLUE};
      }
    `}
`;

export const Checkbox = ({
  name = "",
  label = "",
  selected,
  onChange = () => {},
}) => {
  const handleChange = () => {
    const newValue = !selected;
    onChange(newValue);
  };

  return (
    <Flex data-testid={`checkbox-item-${name}`}>
      <CheckboxIcon selected={Boolean(selected)} onClick={handleChange} />
      <Text>{label}</Text>
    </Flex>
  );
};
