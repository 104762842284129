import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const FONT_SIZE = "14px";
const RADIUS = "20px";
const RADIUS_COMPACT = "8px";
const PADDING = "8px 16px";
const PADDING_COMPACT = "4px 6px";

export const Pill = styled.span`
  cursor: pointer;
  background-color: ${COLOR.PURPLE_LIGHT};
  border-radius: ${RADIUS};
  padding: ${PADDING};
  font-size: ${FONT_SIZE};
  font-weight: 300;
  line-height: calc(${FONT_SIZE} * 2.2);

  ${(props) =>
    props.compact &&
    css`
      border-radius: ${RADIUS_COMPACT};
      padding: ${PADDING_COMPACT};
    `}

  ${(props) =>
    props.selected &&
    css`
      font-weight: 400;
      color: ${COLOR.WHITE};
      background-color: ${COLOR.PURPLE};
    `}

  ${(props) =>
    props.blue &&
    css`
      background-color: ${COLOR.LIGHT_BLUE};
    `}

  ${(props) =>
    props.blue &&
    props.selected &&
    css`
      background-color: ${COLOR.LATITUDE_BLUE};
    `}

  ${(props) =>
    props.grey &&
    css`
      background-color: ${COLOR.LIGHT_GREY};
    `}
`;
