import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { BREAKPOINT, COLOR } from "./theme";

const SPACING = "30px";
const MAX_WIDTH = "600px";

const StyledContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: ${MAX_WIDTH};
  background-color: ${COLOR.WHITE};
  padding: ${SPACING};
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    width: 100%;
  }
`;

export const Modal = ({ children, allowClickOutside, onClickOutside, ...props }) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // hide when clicked outside
      if (
        allowClickOutside &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        isVisible && setVisible(false);
        onClickOutside && onClickOutside();
      }
    };

    document.body.classList.add("modal");
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.body.classList.remove("modal");
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isVisible]);

  return (
    <StyledContainer ref={ref} data-testid="modal" {...props}>
      {children}
    </StyledContainer>
  );
};
