import styled, { css } from "styled-components";
import { COLOR } from "./theme";

export const Box = styled.div`
  border: 1px solid ${COLOR.LIGHT_GREY};
  border-radius: 8px;

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;
