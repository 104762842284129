export const currencyFormat = (price) => {
  const withCommas = Number(price).toLocaleString("en-au", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return withCommas;
};

export const substractAmount = (value1, value2) => {
  let result = 0;

  if (Number(value1) > Number(value2)) {
    result = Number(value1) - Number(value2);
  }

  return result.toFixed(2);
};

/**
 * Format the input to 2 decimal points
 * i.e. 10.235 -> 10.24
 * @param {string} amount Number or String representation of a number
 * @returns {float} float representation of input
 */
export const formatAmountCents = (amount = 0) => {
  //in case it's string 
  let float = parseFloat(amount);
  //return float representation
  return parseFloat(float.toFixed(2));
}