import { Text } from "basis";
import React from "react";
import { currencyFormat } from "../utils";

const FormattedPrice = ({
  price,
  negative = "",
  align = "left",
  suffix = "",
  ...restProps
}) => {
  return (
    <Text align={align} testId="formatted-price" {...restProps}>
      {!isNaN(price) ? `${negative && "-"} $${currencyFormat(price)}` : "$0.00"}
      {suffix}
    </Text>
  );
};

export default FormattedPrice;
