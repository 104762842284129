import React from "react";
import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const StyledLabel = styled.div`
  width: 100%;
  & p:nth-child(2) {
    padding: 8px 0;
    font-weight: 200;

    ${(props) =>
      props.padding &&
      css`
        padding: ${props.padding};
      `}

    ${(props) =>
      props.grey &&
      css`
        background-color: ${COLOR.GREY};
      `}

    ${(props) =>
      props.bold &&
      css`
        font-weight: 400;
      `}
  }
  }
`;

export const Label = ({ title, children, ...props }) => (
  <StyledLabel {...props}>
    {title && <p>{title}</p>}
    {children}
  </StyledLabel>
);
