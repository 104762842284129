import React from "react";
import { Text } from "basis";
import styled, { css } from "styled-components";
import { BREAKPOINT } from "./theme";

const Wrapper = styled.div`
  ${(props) =>
    props.mobileTextAlign &&
    css`
      p {
        @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
          text-align: ${props.mobileTextAlign};
        }
      }
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.font14 &&
    css`
      p {
        font-size: 14px;
      }
    `}

  ${(props) =>
    props.color &&
    css`
      p {
        color: ${props.color};
      }
    `}

    ${(props) =>
      props.capitalize &&
      css`
        p {
          text-transform: capitalize;
        }
      `}
    
`;

export default ({
  mobileTextAlign,
  fullWidth,
  font14,
  color,
  capitalize,
  children,
  ...restProps
}) => {
  const containerProps = {
    ...restProps,
    mobileTextAlign,
  };

  return (
    <Wrapper
      mobileTextAlign={mobileTextAlign}
      fullWidth={fullWidth}
      font14={font14}
      color={color}
      capitalize={capitalize}
    >
      <Text {...containerProps}>{children}</Text>
    </Wrapper>
  );
};
