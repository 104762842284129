import React from "react";
import styled from "styled-components";

import Container from "./Container";
import Flex from "./Flex";

const SIZE = "40px";

const StyledImage = styled.img`
  max-width: 65%;
  height: ${SIZE};
  max-height: ${SIZE};
`;

const MerchantLogo = ({ src }) => (
  <Container>
    <Flex padding="20px 0">
      <StyledImage src={src} alt="merchant-logo" data-testid="merchant-logo" />
    </Flex>
  </Container>
);

export default MerchantLogo;
