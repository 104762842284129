export const getEventDetail = ({
  eventName,
  action,
  page,
  otherDetails,
  merchantId,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const details = {
    event: eventName,
    action: action,
    eventDetails: {
      merchantId: merchantId ? merchantId : queryParams.get("merchantId") || "",
      page,
      ...otherDetails,
    },
  };
  return details;
};
