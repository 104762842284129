import styled, { css } from "styled-components";
import { BREAKPOINT } from "./theme";

export const Responsive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;

  & > div {
    margin-bottom: 12px;
  }

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
    ${(props) =>
    props.justifyRow &&
    css`
      justify-content: ${props.justifyRow};
    `}

    & > div {
      margin-right: 12px;
    }
  }
`;
