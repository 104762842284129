import React from "react";
import { Container } from "basis";
import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const BOX_SHADOW =
  "0px 8px 48px #eee, 0px 4px 8px rgba(66, 71, 76, 0.06),0px 0px 1px rgba(66, 71, 76, 0.48)";

const Wrapper = styled.div`
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${COLOR.MID_GREY};
    `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 6px;
      padding: 6px;
    `}

  ${(props) =>
    props.bg &&
    css`
      background: ${props.bg};
    `}
  
  ${(props) =>
    props.fontColor &&
    css`
      color: ${props.fontColor};
    `}
  
  ${(props) =>
    props.darkBorder &&
    css`
      border: 1px solid ${COLOR.MID_GREY};
    `}

  ${(props) =>
    props.lightBorder &&
    css`
      border: 1px solid ${COLOR.LIGHT_GREY};
    `}

  ${(props) =>
    props.lightBorderTop &&
    css`
      border-top: 1px solid ${COLOR.LIGHT_GREY};
    `}

  ${(props) =>
    props.maxWidth &&
    Number(props.maxWidth) > 0 &&
    css`
      max-width: ${props.maxWidth}px;
      margin: 0 auto;
      width: 100%;
    `}

  ${(props) =>
    props.minHeight &&
    Number(props.minHeight) > 0 &&
    css`
      min-height: ${props.minHeight}px;
    `}

  ${(props) =>
    props.gutters &&
    css`
      padding-left: 15px;
      padding-right: 15px;
    `}

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft}px;
    `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: ${BOX_SHADOW};
    `}

  ${(props) =>
    props.gutters &&
    props.maxWidth &&
    css`
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 30px);
    `}
    
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.divider &&
    css`
      border-bottom: 1px dashed ${COLOR.LIGHT_GREY};
    `}

  ${(props) =>
    props.infoBorder &&
    css`
      border-bottom: 12px solid ${COLOR.INFO};
    `}
`;

const ALLOWED_BASIS_BG = [
  "transparent",
  "white",
  "grey.t03",
  "grey.t05",
  "grey.t07",
  "secondary.lightBlue.t15",
  "secondary.lightBlue.t25",
  "primary.blue.t100",
];

export default ({
  border,
  rounded,
  maxWidth,
  minHeight,
  fullWidth,
  children,
  gutters,
  marginLeft,
  marginBottom,
  shadow,
  bg,
  fontColor,
  darkBorder,
  lightBorder,
  lightBorderTop,
  divider,
  infoBorder,
  ...restProps
}) => {
  const containerProps = {
    ...restProps,
    ...(ALLOWED_BASIS_BG.includes(bg) ? { bg } : {}),
  };

  return (
    <Wrapper
      border={border}
      fullWidth={fullWidth}
      rounded={rounded}
      darkBorder={darkBorder}
      lightBorder={lightBorder}
      lightBorderTop={lightBorderTop}
      maxWidth={maxWidth}
      minHeight={minHeight}
      gutters={gutters}
      marginLeft={marginLeft}
      marginBottom={marginBottom}
      shadow={shadow}
      divider={divider}
      bg={bg}
      fontColor={fontColor}
      infoBorder={infoBorder}
    >
      <Container {...containerProps}>{children}</Container>
    </Wrapper>
  );
};
