import React from "react";
import { Button } from "basis";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  ${(props) =>
    props.smallFont &&
    css`
      && button {
        font-size: 14px;
      }
    `}

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      && button {
        width: 100%;
      }
    `}

  ${(props) =>
    props.hoverColor &&
    css`
      && button:hover {
        cursor: pointer;
        color: ${props.hoverColor};

        svg > path {
          fill: ${props.hoverColor};
        }
      }
    `}

  ${(props) =>
    props.showButton
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  button {
    cursor: pointer;
  }
`;

export default ({
  showButton,
  smallFont,
  margin,
  fullWidth,
  hoverColor,
  ...restProps
}) => {
  const buttonProps = {
    ...restProps,
  };

  return (
    <Wrapper
      showButton={showButton}
      smallFont={smallFont}
      margin={margin}
      fullWidth={fullWidth}
      hoverColor={hoverColor}
    >
      <Button {...buttonProps}></Button>
    </Wrapper>
  );
};
