import React from "react";
import { CURRENCY } from "../core/constants";
import Icon from "./Icon/Icon";
import { COLOR } from "./theme";

const VARIANT_MAP = new Map([
  ["blue", COLOR.LATITUDE_BLUE],
  ["white", COLOR.WHITE],
]);

const Logo = ({ currency = "", mini, variant }) => {
  const color = VARIANT_MAP.has(variant)
    ? VARIANT_MAP.get(variant)
    : COLOR.LATITUDE_BLUE;

  if (mini) {
    return <Icon type="latitudeLogoIcon" medium color={color} />;
  }

  if (currency === CURRENCY.NZD) {
    return <Icon type="gem" extraLarge color={color} />;
  }

  return <Icon type="latitude" large color={color} />;
};

export default Logo;
