import React from "react";
import styled, { css } from "styled-components";
import { COLOR } from "./theme";
import Icon from "./Icon/Icon";

const BUTTON_SIZE = "3px";
const ICON_SIZE = "22px";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${COLOR.GREY};
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background: transparent;

  ${(props) =>
    props.padding &&
    css`
      margin: ${props.padding};
    `}
  width: calc(100% - 30px);
  height: 48px;
`;
const StyledButton = styled.button`
  cursor: pointer;
  background: ${COLOR.GREY};
  outline: none;
  border: none;
  min-width: ${BUTTON_SIZE};
  min-height: ${BUTTON_SIZE};
  right: 0;
  top: 0;
`;

export const CustomInput = ({
  placeholder,
  searchText = "",
  onSearch = () => {},
  padding = "0px 30px 0px 2px",
  icon = false, 
  iconType = "dollar"
}) => {

  return (
    <StyledWrapper>
      {icon&&
      <StyledButton>
        <Icon
            type={iconType}
            size={ICON_SIZE}
            color={COLOR.HIGHLIGHT_BLUE}
            hoverColor={COLOR.LATITUDE_BLUE}
          />
        </StyledButton>
       }
      <StyledInput
        placeholder={placeholder}
        value={searchText}
        onChange={(event)=>onSearch(event.target.value)}
        padding={padding}
      />

    </StyledWrapper>
  );
};
