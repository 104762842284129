import styled from "styled-components";

import { BREAKPOINT } from "./theme";

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    flex-direction: column;
  }

  div {
    width: 49%;

    @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
      margin: 0 0 12px 0;
      width: 100%;
    }

    > button,
    > a {
      cursor: pointer;
      width: 100%;
    }
  }
`;
