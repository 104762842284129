import React from "react";
import { Text } from "basis";

import Flex from "./Flex";
import Logo from "./Logo";
import Container from "./Container";
import Arrow from "./Arrow";

const Header = ({ title, currency = "", infoBorder }) => (
  <>
    <Container bg="white" fullWidth shadow infoBorder={infoBorder}>
      <Container maxWidth="1080" gutters>
        <Flex padding="15px 0">
          <Logo currency={currency} />
        </Flex>
      </Container>
    </Container>
    {title && (
      <Container bg="secondary.lightBlue.t15">
        <Container maxWidth="1080" gutters>
          <Flex alignContent="center">
            <Text textStyle="heading4" color="primary.blue.t100">
              {title}
            </Text>
            <Arrow />
          </Flex>
        </Container>
      </Container>
    )}
  </>
);

export default Header;
