import React from "react";
import styled from "styled-components";
import { Stack } from "basis";
import { COLOR } from "./theme";

const Wrapper = styled.div`
  border: 1px solid ${COLOR.MID_GREY};
  padding: 15px 20px;
  margin: 10px 0;
  background: ${COLOR.GREY};

  .item(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid ${COLOR.MID_GREY};
  }
`;

export default ({ children }) => (
  <Wrapper>
    <Stack gap="2">{children}</Stack>
  </Wrapper>
);
