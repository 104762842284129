import React from "react";
import { Text } from "basis";
import styled from "styled-components";

import Flex from "../Flex";

import { RadioCircle } from "./RadioCircle";

const StyledFlex = styled((props) => <Flex {...props} />)`
  cursor: pointer;
`;

export const RadioItem = ({ children, isSelected, isDisabled = false, onChange, value, label }) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <StyledFlex
      margin="0 0 4px 0"
      padding="14px 20px"
      onClick={handleChange}
      grey={!isSelected}
      blue={isSelected}
      data-testid={`radio-item-${value}`}
    >
      <RadioCircle selected={isSelected} disabled={isDisabled} />

      {/* render label when children is not provided */}
      {!children && <Text>{label}</Text>}

      {/* render children when provided */}
      {children}
    </StyledFlex>
  );
};
