import React from "react";
import { Link } from "basis";
import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const StyledLink = styled.div`
  cursor: pointer;

  ${(props) =>
    props.noMaxHeight &&
    css`
      && {
        max-height: none;
      }
    `}

  ${(props) =>
    props.inline &&
    css`
      && {
        display: inline;
      }
    `}

  ${(props) =>
    props.smallFont &&
    css`
      && a span {
        color: ${COLOR.DARK_GREY};
        font-size: 14px;
        border-bottom-color: ${COLOR.DARK_GREY};
      }
    `}

  ${(props) =>
    props.fontColor &&
    css`
      && a span {
        color: ${props.fontColor};
      }
      && a span:hover {
        color: ${props.fontColor};
        background-color: transparent;
      }
    `}

  ${(props) =>
    props.font14 &&
    css`
      && a span {
        font-size: 14px;
      }
    `}
`;

export default ({
  noMaxHeight,
  children,
  smallFont,
  font14,
  inline,
  fontColor,
  ...props
}) => (
  <StyledLink
    inline={inline}
    noMaxHeight={noMaxHeight}
    smallFont={smallFont}
    font14={font14}
    fontColor={fontColor}
  >
    <Link href="" {...props}>
      {children}
    </Link>
  </StyledLink>
);
