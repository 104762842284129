import React from "react";
import styled from "styled-components";
import { COLOR } from "./theme";

const ARROW_WIDTH = "60px";

const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
  max-height: calc(${ARROW_WIDTH} * 2.3);
`;

const ArrowLeft = styled.div`
  border: solid ${COLOR.SECONDARY_LIGHT_BLUE_25}};
  border-width: 0 ${ARROW_WIDTH} ${ARROW_WIDTH} 0;
  display: inline-block;
  padding: ${ARROW_WIDTH};
  margin-left: ${ARROW_WIDTH};
  transform: rotate(130deg);
`;

const Arrow = () => (
  <Wrapper>
    <ArrowLeft />
  </Wrapper>
);

export default Arrow;
