import React, { useState, useEffect } from "react";

import Flex from "../Flex";

import { RadioItem } from "./RadioItem";

export const RadioGroup = ({
  selectFirstOption,
  options = [],
  isDisabled = false,
  renderItem = () => {},
  onChange = () => {},
}) => {
  const [firstItem] = options;
  const [selected, setSelected] = useState(null);

  const handleChange = (value) => {
    setSelected(value);
    onChange(value);
  };

  useEffect(() => {
    if (selectFirstOption && options?.length > 0) {
      handleChange(firstItem?.value);
    }
  }, []);

  // no options, return null
  if (options?.length === 0) {
    return null;
  }

  // single option, render option without selection
  if (options?.length === 1) {
    return (
      <Flex margin="0 0 4px 0" padding="14px 20px" blue>
        {renderItem(firstItem)}
      </Flex>
    );
  }

  // render options with selection
  return (
    <>
      {options.map((optionItem) => (
        <RadioItem
          key={optionItem?.value}
          label={optionItem?.label}
          value={optionItem?.value}
          isDisabled={isDisabled}
          isSelected={selected === optionItem?.value}
          onChange={handleChange}
        >
          {renderItem(optionItem)}
        </RadioItem>
      ))}
    </>
  );
};
