import React from "react";
import { Button, Icon, Stack, Text } from "basis";
import IconSVG from './Icon/Icon'
import styled, { css } from "styled-components";
import { COLOR } from "./theme";

const BORDER_SIZE = "4px";
const LETTER_ICON_TEXT_SIZE = "16px";
const LETTER_ICON_SIZE = "40px";

const StyledWrapper = styled.div`
  button {
    cursor: pointer;
    width: 100%;
  }

  button > span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  border-left: ${BORDER_SIZE} solid transparent;

  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${COLOR.LIGHT_BLUE};
      border-left: ${BORDER_SIZE} solid ${COLOR.HIGHLIGHT_BLUE};
    `}
`;

export const IconButton = ({
  icon,
  title,
  active,
  variant,
  subTitle,
  onClick,
  inline,
  iconsvg,
  ...props
}) => {
  return (
    <StyledWrapper active={active} inline={inline} onClick={onClick}>
      <Button variant="icon" props={props}>
        {icon && <Icon name={icon} />}
        {iconsvg && <IconSVG type={iconsvg} display="contents" />}
        <Stack align="right">
          <Text textStyle="body2">
            <strong>{title}</strong>
          </Text>
          <Text textStyle="body2">{subTitle}</Text>
        </Stack>
      </Button>
    </StyledWrapper>
  );
};
