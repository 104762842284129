import { logger } from "../core";
import { ipsi } from "../core/config";

import isBrowser from "./isBrowser";

// error types
export const ERROR_TYPE = {
  INVALID_ORIGIN: "invalid-origin",
};

let attachedEventHandlers = {};

const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent("on" + eventName, eventHandler);
  }

  // add to local registry of event handlers
  attachedEventHandlers[eventName] = eventHandler;
};

const unBindEvent = (element, eventName) => {
  // find matching event handler
  let eventHandler = attachedEventHandlers[eventName];

  // return when matching handler is not found
  if (!eventHandler) {
  }
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler, false);
  } else if (element.detachEvent) {
    element.detachEvent("on" + eventName, eventHandler);
  }

  // clean up local registry
  delete attachedEventHandlers[eventName];
};

const hasParentWindow = () => window.parent !== window.top;

const handleMessage = (e, { onMessage = () => {}, onError = () => {} }) => {
  if (e.origin !== `https://${ipsi.baseUrl}`) {
    const flatData = JSON.stringify(e?.data || "");
    logger.error(`handleMessage() origin ${e.origin} with data ${flatData} not supported`);

    onError(e, ERROR_TYPE.INVALID_ORIGIN);

    return;
  }

  onMessage(e, JSON.parse(e.data));
};

// listen to message from parent
const subscribe = (options) => {
  if (!isBrowser()) {
    return;
  }

  bindEvent(window, "message", (e) => handleMessage(e, options));
};

// unsubscribe
const unsubscribe = (options) => {
  if (!isBrowser()) {
    return;
  }

  unBindEvent(window, "message", (e) => handleMessage(e, options));
};

// send message to parent
const sendMessage = (message) => {
  if (!hasParentWindow()) {
    logger.error("sendMessage(): No parent window");
  }

  if (!isBrowser()) {
    return;
  }

  window.parent.postMessage(JSON.stringify(message), "*");
};

export default {
  hasParentWindow,
  subscribe,
  unsubscribe,
  sendMessage,
};
