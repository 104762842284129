import React from "react";
import styled, { css } from "styled-components";
import { Container, Text } from "basis";

import { COLOR } from "./theme";

// TODO: use Select from basis
const StyledSelect = styled.select`
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${COLOR.BLACK};
  height: 48px;
  padding-left: 16px;
  padding-right: 40px;
  margin: 0;
  border: 0;
  border-radius: 0;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' role='img' aria-label='Triangle down'%3E%3Cpath d='M20.747 14.509l-4.181 4.25a.786.786 0 01-1.132 0l-4.179-4.247a.885.885 0 01-.231-.827c.07-.3.287-.536.569-.62.282-.084 8.607-.101 8.912.035a.86.86 0 01.495.802.874.874 0 01-.253.607z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  align-self: flex-start;
  background-color: ${COLOR.WHITE};
  width: 100%;

  ${(props) =>
    props.grey &&
    css`
      background-color: ${COLOR.GREY};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.25;
      background-color: ${COLOR.WHITE};
    `};
`;

const Select = ({
  grey,
  label,
  selected,
  disabled,
  placeholder,
  options,
  onChange,
  margin,
}) => {
  const placeholderOption = (placeholder || !selected) && (
    <option data-testid="select-option-placeholder" value={null}>{placeholder}</option>
  );

  const selectOptions = options.map(({ label, value }) => (
    <option data-testid="select-option" key={value} value={value}>
      {label}
    </option>
  ));

  return (
    <>
      {label && (
        <Text color="grey.t75">
          <strong>{label} </strong>
        </Text>
      )}

      <Container margin={margin}>
        <StyledSelect
          grey={grey}
          value={selected}
          disabled={disabled}
          onChange={(e) => onChange(e, e.target?.value)}>
          {placeholder && placeholderOption}
          {selectOptions}
        </StyledSelect>
      </Container>
    </>
  );
};

export default Select;
