import styled, { css } from "styled-components";
import { BREAKPOINT } from "./theme";

const COLUMN_TEMPLATE_DEFAULT = "1fr";

export const Column = styled.div`
    display: grid;
    max-width: ${(props) => props.maxWidth ?? BREAKPOINT.SIZE_WIDE_DESKTOP};
    grid-template-columns: ${(props) =>
      props?.template ?? COLUMN_TEMPLATE_DEFAULT};

    ${(props) =>
      props.padding &&
      css`
        padding: ${(props) => props.padding};
      `}

    ${(props) =>
      props.bg &&
      css`
        background-color: ${(props) => props.bg};
      `}

    ${(props) =>
      props.border &&
      css`
        border-bottom: ${(props) => props.border};
      `}

    ${(props) =>
      props.borderBottom &&
      css`
        border-bottom: ${(props) => props.borderBottom};
      `}

    ${(props) =>
      props?.templateMobile &&
      css`
        /* Use mobile column template */
        grid-template-columns: ${props?.templateMobile};
      `}

    ${(props) =>
      props?.templateTablet &&
      css`
        @media screen and (min-width: ${BREAKPOINT.SIZE_TABLET}) {
          /* Use tablet column template */
          grid-template-columns: ${props?.templateTablet};
        }
      `}

    ${(props) =>
      props?.templateDesktop &&
      css`
        @media screen and (min-width: ${BREAKPOINT.SIZE_DESKTOP}) {
          /* Use desktop column template */
          grid-template-columns: ${props?.templateDesktop};
        }
      `}
`;
