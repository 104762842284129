import React from "react";
import { Text, Flex, Link } from "basis";
import { Icon } from ".";

export const MobileAppBaner = () => {
  return (
    <>
      <Text textStyle="heading5" align="center">
        Download the Latitude App
      </Text>

      <Text align="center"  margin="4 0 0 0">
        Track your Interest Free purchases, manage your payments and view your
        statements with the Latitude App.
      </Text>

      <Flex placeItems="center">
        <Link
          href="https://apps.apple.com/app/apple-store/id1373059866"
          margin="4 3 0 0"
          newTab
        >
          <Icon size="138px" type="appStoreBadge" />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp"
          margin="4 0 0 3"
          newTab
        >
          <Icon size="138px" type="googlePlayBadge" />
        </Link>
      </Flex>
    </>
  );
};
