import analytics from "./analytics.svg";
import appStoreBadge from "./app-store-badge.svg";
import arrowChevronRight from "./arrow-chevron-right.svg";
import authorised from "./authorised.svg";
import bags from "./bags.svg";
import calendar from "./calendar.svg";
import chevron from "./chevron.svg";
import computer from "./computer.svg";
import confetti from "./confetti.svg";
import davidJonesIcon from "./davidJonesIcon.svg";
import davidJonesPremiere from "./davidJonesPremiere.svg";
import davidJonesPrestige from "./davidJonesPrestige.svg";
import declined from "./declined.svg";
import emptyCreditCard from "./emptyCreditCard.svg";
import emptyPlant from "./emptyPlant.svg";
import errorApiException from "./error-api-exception.svg";
import errorExcludedItems from "./error-excluded-items.svg";
import errorLessThanMinimum from "./error-less-than-minimum.svg";
import errorMoreThanMaximum from "./error-more-than-maximum.svg";
import errorNoGiftCard from "./error-no-gift-card.svg";
import errorNoPromotion from "./error-invalid-promotion.svg";
import errorTimeOut from "./error-time-out.svg";
import external from "./external.svg";
import faqs from "./faqs.svg";
import file from "./file.svg"
import filter from "./filter.svg";
import folder from "./folder.svg";
import gem from "./gem.svg";
import gemInterestFree from "./gemInterestFree.svg";
import genoaPay from "./genoaPay.svg";
import googlePlayBadge from "./google-play-badge.svg";
import home from "./home.svg";
import heart from "./heart.svg";
import interestFree from "./interestFree.svg";
import iconArrow from "./icon-arrow.svg";
import iconGreenTick from "./icon-paid.svg";
import iconCalendar from "./icon-calendar.svg";
import iconClose from "./icon-close.svg";
import iconCapture from "./icon-capture.svg";
import iconExclamation from "./icon-exclamation.svg";
import iconTick from "./icon-tick.svg";
import iconRedCross from "./icon-unpaid.svg";
import iconProfile from "./icon-profile.svg";
import iconKebab from "./icon-kebab.svg";
import iconRefresh from "./icon-refresh.svg";
import iconRefundStatus from "./icon-refund.svg";
import iconInfo from "./icon-info.svg";
import iconRefund from "./icon-refundAction.svg";
import iconView from "./icon-view.svg";
import iconVoid from "./icon-void.svg";
import iconSearch from "./icon-search.svg";
import latitude from "./latitude.svg";
import latitudeInterestFree from "./latitudeInterestFree.svg";
import latitudePay from "./latitudePay.svg";
import latitudePayCard from "./latitudePayCard.svg";
import latitudeLogoIcon from "./latitudeLogoIcon.svg";
import lightbulb from './lightbulb.svg';
import list from './list.svg';
import logout from "./logout.svg";
import moneyPlant from "./moneyPlant.svg";
import orders from "./orders.svg";
import partnerResourceToolkit from "./partnerResourceToolkit.svg";
import party from "./party.svg";
import pdf from "./pdf.svg";
import pending from "./pending.svg";
import awaitingPayment from "./awaiting-payment.svg";
import questionBox from "./question-box.svg";
import report from "./report.svg";
import shopify from "./shopify.svg";
import shoppingBag from "./shoppingBag.svg";
import shoppingBags from "./shoppingBags.svg";
import smiley from "./smiley.svg";
import support from "./support.svg";
import ascendingSort from "./sort-ascending.svg";
import descendingSort from "./sort-descending.svg";
import sortDefault from "./sort-default.svg";
import statement from "./statement.svg";
import statements from "./statements.svg";
import stopwatch from "./stopwatch.svg";
import sunnies from "./sunnies.svg";
import trainingGuides from "./trainingGuides.svg";
import xlsx from "./xlsx.svg";
import store from "./store.svg";
import dollar from "./dollar.svg"

export default new Map([
  ["analytics", analytics],
  ["appStoreBadge", appStoreBadge],
  ["arrowChevronRight", arrowChevronRight],
  ["authorised", authorised],
  ["bags", bags],
  ["calendar", calendar],
  ["confetti", confetti],
  ["computer", computer],
  ["chevron", chevron],
  ["declined", declined],
  ["davidJonesIcon", davidJonesIcon],
  ["davidJonesPremiere", davidJonesPremiere],
  ["davidJonesPrestige", davidJonesPrestige],
  ["emptyCreditCard", emptyCreditCard],
  ["emptyPlant", emptyPlant],
  ["errorApiException", errorApiException],
  ["errorExcludedItems", errorExcludedItems],
  ["errorLessThanMinimum", errorLessThanMinimum],
  ["errorMoreThanMaximum", errorMoreThanMaximum],
  ["errorNoGiftCard", errorNoGiftCard],
  ["errorInvalidPromotion", errorNoPromotion],
  ["errorTimeOut", errorTimeOut],
  ["external", external],
  ["faqs", faqs],
  ["file", file],
  ["filter", filter],
  ["folder", folder],
  ["gem", gem],
  ["genoaPay", genoaPay],
  ["gemInterestFree", gemInterestFree],
  ["googlePlayBadge", googlePlayBadge],
  ["home", home],
  ["heart", heart],
  ["iconArrow", iconArrow],
  ["iconCalendar", iconCalendar],
  ["iconClose", iconClose],
  ["iconTick", iconTick],
  ["iconExclamation", iconExclamation],
  ["iconGreenTick", iconGreenTick],
  ["iconProfile", iconProfile],
  ["iconRedCross", iconRedCross],
  ["iconCapture", iconCapture],
  ["iconInfo", iconInfo],
  ["iconKebab", iconKebab],
  ["iconRefresh", iconRefresh],
  ["iconRefund", iconRefund],
  ["iconRefunded", iconRefundStatus],
  ["iconSearch", iconSearch],
  ["iconView", iconView],
  ["iconVoid", iconVoid],
  ["interestFree", interestFree],
  ["latitude", latitude],
  ["latitudeInterestFree", latitudeInterestFree],
  ["latitudePay", latitudePay],
  ["latitudePayCard", latitudePayCard],
  ["latitudeLogoIcon", latitudeLogoIcon],
  ["list", list],
  ["lightbulb", lightbulb],
  ["logout", logout],
  ["moneyPlant", moneyPlant],
  ["orders", orders],
  ["partnerResourceToolkit", partnerResourceToolkit],
  ["party", party],
  ["pdf", pdf],
  ["pending", pending],
  ["awaitingPayment", awaitingPayment],
  ["questionBox", questionBox],
  ["shopify", shopify],
  ["shoppingBag", shoppingBag],
  ["shoppingBags", shoppingBags],
  ["smiley", smiley],
  ["support", support],
  ["ascendingSort", ascendingSort],
  ["descendingSort", descendingSort],
  ["report", report],
  ["sortDefault", sortDefault],
  ["statement", statement],
  ["statements", statements],
  ["stopwatch", stopwatch],
  ["sunnies", sunnies],
  ["trainingGuides", trainingGuides],
  ["xlsx", xlsx],
  ["store", store],
  ["dollar", dollar]
]);
