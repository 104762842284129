import React from "react";
import { Text } from "basis";

import Link from "../../Link";
import { currencyFormat } from "../../../utils";
import { MONTHLY_FEES, RATES } from "./constants";

export const Minimum = ({
  onLearnMoreClick = () => {},
  currency,
  learnMoreURL,
  au,
  nz,
}) => {
  const monthlyPrice = currencyFormat(MONTHLY_FEES[currency]);

  const AU = () => (
    <>
      <Text textStyle="body2" margin="0 0 3">
        <strong>
          Monthly payments must be made during the promotional period
        </strong>
      </Text>

      <Text textStyle="body2" margin="0 0 3">
        Paying only the minimum monthly payment will not pay out the purchase
        before the end of the plan. At the end of the plan, interest will be
        charged at {RATES.GEM_VISA_AU}% p.a. Account service fee of $
        {monthlyPrice} per month applies.
      </Text>

      <Link onClick={onLearnMoreClick} href={learnMoreURL} newTab smallFont>
        Learn more
      </Link>
    </>
  );

  const NZ = () => (
    <>
      <Text textStyle="body2" margin="0 0 3">
        <strong>
          Monthly payments must be made during the promotional period
        </strong>
      </Text>

      <Text textStyle="body2" margin="0 0 3">
        Paying only the minimum monthly payment will not pay out the purchase
        before the end of the plan. Interest rate (currently Gem Visa{" "}
        {RATES.GEM_VISA_NZ}% p.a. / Gem CreditLine {RATES.GEM_CREDITLINE}% p.a.)
        applies after interest free term ends.
      </Text>

      <Link onClick={onLearnMoreClick} href={learnMoreURL} newTab smallFont>
        Learn more
      </Link>
    </>
  );

  return (
    <>
      {au && <AU />}
      {nz && <NZ />}
    </>
  );
};
