import React from 'react';
import styled from 'styled-components';

import { Container, Text } from "basis";
import { BarChart, Bar, XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { COLOR } from './theme';

const TooltipWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  padding: 10px;
  border-radius: 10px;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <Text color="white">{`Range: ${label}-${label + 100}ms`}</Text>
        <Text color="white">{`Count: ${payload[0].value}`}</Text>
      </TooltipWrapper>
    );
  }

  return null;
};

const BarGraphStacked = ({ title, chartData, yLabel, xDataKey, lineDataKeys, showLegend }) => {
  return (
    <>
      <Container bg="white" padding="4" height="240">
        <Container margin="0 0 4 2">
          <Text as="h5" textStyle="heading5">{title}</Text>
        </Container>
        <ResponsiveContainer width={'100%'} height={'90%'}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xDataKey} tick={{ fontSize: 12 }} />
            <YAxis width={40} tick={{ fontSize: 12 }}>
              {yLabel && <Label value={yLabel} offset={-10} angle={-90} position="left" />}
            </YAxis>
            <Tooltip />
            {showLegend && <Legend />}
            {lineDataKeys.map((item, index) => (
              <Bar key={index} dataKey={item.dataKey} fill={item.fill} name={item.name} stackId={item.stackId} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Container >
    </>
  );
};

export default BarGraphStacked;
