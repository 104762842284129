import { CURRENCY } from "../../../core/constants";

export const MONTHLY_FEES = {
  [CURRENCY.AUD]: 10.95,
  [CURRENCY.NZD]: 0,
};

export const FEES = {
  GEM_VISA: 65,
  GEM_VISA_HALF_YEARLY: (65 / 2).toFixed(2),
  GEM_CREDITLINE: 65,
  ESTABLISHMENT: 55,
};

export const RATES = {
  GEM_VISA_AU: 29.99,
  GEM_VISA_NZ: 29.49,
  GEM_CREDITLINE: 29.95,
};
