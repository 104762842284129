import React from "react";
import Text from "./Text";

const HeaderValueText = ({ title, isCurrency, children }) => {
  const prefix = isCurrency ? "$" : "";

  const formattedChildren = React.Children.toArray(children).map((child) => {
    if (React.isValidElement(child)) {
      return child;
    } else {
      return String(child);
    }
  });

  return (
    <Text>
      <strong>{title}: </strong> {prefix}
      {formattedChildren}
    </Text>
  );
};

export default HeaderValueText;
