import React from "react";
import { Message } from "basis";
import { Container } from ".";

export const TestMode = () => (
  <Container padding="6 0 0 0" maxWidth="1110">
    <Message severity="warning-or-significant" bg="secondary.lightBlue.t25">
      This shop is currently running in test mode. All transactions are
      simulated and no payments will be processed.
    </Message>
  </Container>
);
