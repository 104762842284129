import React from "react";
import styled, { css } from "styled-components";
import { COLOR } from "../theme";

import RadioSvg from "./selected.svg";

const CIRCLE_SIZE = "24px";

export const RadioCircle = styled((props) => <RadioSvg {...props} />)`
  height: ${CIRCLE_SIZE};
  width: ${CIRCLE_SIZE};

  & circle {
    fill: ${COLOR.WHITE};
  }

  ${(props) =>
    props.selected &&
    css`
      & circle[data-id="inner-circle"] {
        fill: ${COLOR.LATITUDE_BLUE};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      & circle[data-id="inner-circle"] {
        fill: ${COLOR.MID_GREY};
      }
    `}
`;
