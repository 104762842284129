import React from "react";
import styled from "styled-components";
import { Flex, Text } from "basis";

import Container from "./Container";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Profile = ({ firstName }) => {
  if (!firstName) {
    return null;
  }

  return (
    <Container divider padding="0 0 4 0">
      <StyledWrapper>
        <Flex height="100%" placeItems="center left">
          <Text textStyle="subtitle1" padding="1">
            <strong>Hi {firstName}</strong>,
          </Text>
        </Flex>
      </StyledWrapper>
    </Container>
  );
};
