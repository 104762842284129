import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { AppProvider } from "@shopify/polaris";

import polaris from "!raw-loader!@shopify/polaris/build/esm/styles.css";
import translations from "@shopify/polaris/locales/en.json";

const COLOR_PRIMARY = "#3f4eae";

const theme = {
  colors: {
    primary: COLOR_PRIMARY,
  },
};

const Wrapper = styled.div`
  height: auto;
`;

export const PolarisProvider = ({ children }) => (
  <AppProvider i18n={translations} theme={theme}>
    <Helmet>
      <style>{polaris}</style>
    </Helmet>
    <Wrapper>
      {children}
    </Wrapper>
  </AppProvider>
);
