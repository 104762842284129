import styled, { css } from "styled-components";
import { BREAKPOINT, COLOR } from "../theme";

const PortalBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${COLOR.LIGHT_GREY};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 907.087 907.087'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' y1='0.006' x2='0.5' y2='1.993' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f2eeea'/%3E%3Cstop offset='0.537' stop-color='%23fff'/%3E%3C/linearGradient%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='907.087' height='907.087' transform='translate(0 3796.621)' fill='url(%23linear-gradient)'/%3E%3C/clipPath%3E%3ClinearGradient id='linear-gradient-3' x1='0.196' y1='0.5' x2='-0.298' y2='0.5' xlink:href='%23linear-gradient'/%3E%3ClinearGradient id='linear-gradient-4' x1='0.237' y1='0.5' x2='0.503' y2='0.5' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(0 -3796.621)'%3E%3Crect id='Rectangle_1' data-name='Rectangle 1' width='907.087' height='907.087' transform='translate(0 3796.621)' fill='url(%23linear-gradient)'/%3E%3Cg id='Group_1' data-name='Group 1' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M1921.905 2783.693l-272.5-190.805L559.022 4150.119 2116.252 5240.5 2307.057 4968 1022.326 4068.426Z' fill='url(%23linear-gradient-3)'/%3E%3Cpath id='Path_2' data-name='Path 2' d='M1873.11 1690.318l-538.47-377.041L-820.018 4390.447 2257.15 6545.1l377.041-538.47L95.494 4229.018Z' fill='url(%23linear-gradient-4)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;

  ${(props) =>
    props.whiteBackgroundOnMobile &&
    css`
      @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
        background: ${COLOR.WHITE};
      }
    `}

  ${(props) =>
    props.centeredContent &&
    css`
      align-content: center;
    `}

  ${(props) =>
    props.displayBlock &&
    css`
      display: block;
    `}
`;

export default PortalBackground;
