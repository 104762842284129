import React from "react";
import { ErrorBoundary } from "../../../core";

import { CURRENCY, PAYMENT_OPTIONS } from "../../../core/constants";

import { Deferred } from "./Deferred";
import { Equal } from "./Equal";
import { Fallback } from "./Fallback";
import { Flexible } from "./Flexible";
import { Minimum } from "./Minimum";
import { generateLearnMoreURL } from "./utils";
import { EVENTS, ACTION, PAGES } from "../../../core/constants";
import { getEventDetail } from "../../../core/event-logger-helper";
import { logger } from "../../../core";

const Description = (props) => {
  const { type } = props?.promotion || {};

  const allProps = prepareProps(props);

  if (type === PAYMENT_OPTIONS.EQUAL) {
    return <Equal {...allProps} />;
  }

  if (type === PAYMENT_OPTIONS.MINIMUM) {
    return <Minimum {...allProps} />;
  }

  if (type === PAYMENT_OPTIONS.DEFERRED) {
    // use flexible while we wait for Deferred to be approved
    return <Flexible {...allProps} />;
    // return <Deferred {...allProps} />;
  }

  if (type === PAYMENT_OPTIONS.FLEXIBLE) {
    return <Flexible {...allProps} />;
  }

  return <Fallback />;
};

const prepareProps = (props) => {
  const { merchantId, amount, currency } = props?.order || {};
  const { label, promotionMonths } = props?.promotion || {};

  if (!merchantId) {
    throw new Error("Promotion Description > invalid order.merchantId");
  }

  if (isNaN(amount)) {
    throw new Error("Promotion Description > invalid order.amount");
  }

  if (![CURRENCY.AUD, CURRENCY.NZD].includes(currency)) {
    throw new Error("Promotion Description > invalid order.currency");
  }

  if (isNaN(promotionMonths)) {
    throw new Error(
      "Promotion Description > invalid promotion.promotionMonths"
    );
  }

  if (!label) {
    throw new Error("Promotion Description > invalid promotion.label");
  }

  const allowPriceBreakdown = props?.promotion?.type === "equal" ? true : false;

  const learnMoreURL = generateLearnMoreURL(
    merchantId,
    amount,
    currency,
    props?.promotion,
    allowPriceBreakdown
  );

  const onLearnMoreClick = (e) => {
    e.preventDefault();

    const details = getEventDetail({
      eventName: EVENTS.LearnMorePromotion,
      action: ACTION.Click,
      page: PAGES.Checkout,
      merchantId,
    });

    logger.info(details);
    window.open(learnMoreURL, "_blank");
  };

  return {
    onLearnMoreClick,
    promotion: props?.promotion,
    amount,
    currency,
    learnMoreURL,
    au: currency === CURRENCY.AUD,
    nz: currency === CURRENCY.NZD,
  };
};

export default (props) => (
  <ErrorBoundary FallbackComponent={Fallback}>
    <Description {...props} />
  </ErrorBoundary>
);
