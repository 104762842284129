import React from "react";
import { Stack, Text } from "basis";
import { Link, Logo } from ".";

import Container from "./Container";
import { COUNTRY } from "../core/constants";

export const Footer = ({ country, bg, fontColor, variant }) => {
  return (
    <>
      <Container padding="10 4" bg={bg} fullWidth lightBorderTop>
        <Container maxWidth="1080" fontColor={fontColor} gutters>
          <Logo variant={variant} />
  
          <Stack gap="4" direction="horizontal" margin="8 0 8 0">
            <Link
              href={
                country === COUNTRY.NZ
                  ? `https://www.gemfinance.co.nz/privacy`
                  : `https://www.latitudefinancial.com.au/privacy`
              }
              fontColor={fontColor}
              smallFont
              newTab
            >
              Privacy and Credit Reporting Policy
            </Link>
  
            <Link
              href={
                country === COUNTRY.NZ
                  ? `https://www.gemfinance.co.nz/terms-and-conditions`
                  : `https://www.latitudefinancial.com.au/terms-and-conditions`
              }
              fontColor={fontColor}
              smallFont
              newTab
            >
              Website Terms and Conditions
            </Link>
  
            <Link
              href={
                country === COUNTRY.NZ
                  ? `https://www.gemfinance.co.nz/security`
                  : `https://www.latitudefinancial.com.au/security`
              }
              fontColor={fontColor}
              smallFont
              newTab
            >
              Security
            </Link>
          </Stack>
  
          <Text textStyle="legal" color={variant}>
            Credit facility provided by Latitude Finance Australia ABN 42 008 583
            588. Australian Credit Licence Number 392145. 130 Lonsdale Street,
            Melbourne 3000
          </Text>
        </Container>
      </Container>
    </>
  );
}
