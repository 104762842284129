import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { name, version } from "../../package.json";

import {
  datadog,
  envConfig,
  isDatadogEnabled,
  isDatadogRUMEnabled,
  logLevel,
} from "./config";

const getLogger = () => {
  if (!datadogLogs.logger) {
    throw new Error("Logger not configured!");
  }

  return datadogLogs.logger;
};

const info = (...params) =>
  isDatadogEnabled ? getLogger().info(...params) : console.log(...params);

const error = (...params) =>
  isDatadogEnabled ? getLogger().error(...params) : console.log(...params);

const debug = (...params) =>
  isDatadogEnabled ? getLogger().debug(...params) : console.log(...params);

const addContext = ({ key, value }) => getLogger().addContext(key, value);

export const SOURCE = {
  SPA: "spa",
  WIDGET: "widget",
};

/**
 *
 * @param {object} source - allowed values (spa|widget)
 * @returns
 */
const init = ({ source = "" }) => {
  if (!isDatadogEnabled) {
    return;
  }

  const { clientToken, site = "datadoghq.com" } = datadog;

  // initialize logs
  datadogLogs.init({
    clientToken,
    site,
    service: name,
    env: envConfig,
    sampleRate: Number(datadog.sampleRate),
    forwardErrorsToLogs: source === SOURCE.SPA, // forward unhandled browser logs
  });

  datadogLogs.logger.setLevel(logLevel);

  // initialize interactions
  if (source === SOURCE.SPA && isDatadogRUMEnabled) {
    datadogRum.init({
      applicationId: datadog.appId,
      clientToken,
      site,
      version,
      env: envConfig,
      sampleRate: Number(datadog.sampleRate),
      trackInteractions: datadog.trackInteractions,
    });
  }
};

export const logger = {
  info,
  error,
  debug,
  addContext,
  init,
};
