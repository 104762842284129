module.exports = {
  appUrl: process.env.APP_URL,
  trainingUrl: process.env.TRAINING_URL,
  analyticsServiceBaseUrl: process.env.ANALYTICS_SERVICE_BASE_URL,
  reportServiceBaseUrl: process.env.REPORT_SERVICE_BASE_URL,
  checkoutServiceBaseUrl: process.env.CHECKOUT_SERVICE_BASE_URL,
  shopifyServiceBaseUrl: process.env.SHOPIFY_SERVICE_BASE_URL,
  assetsBaseUrl: process.env.ASSETS_BASE_URL,
  lscBaseUrl: process.env.LSC_BASE_URL,
  envConfig: process.env.CONFIGURATION,
  branch: process.env.BRANCH,
  commit: process.env.COMMIT,
  isDatadogRUMEnabled: process.env.DATADOG_RUM_ENABLED === "true",
  isDatadogEnabled: process.env.DATADOG_ENABLED === "true",
  isPurchaseDisabled: process.env.PURCHASE_DISABLED === "true",
  testCredentials: process.env.TEST_CREDENTIALS,
  logLevel: process.env.LOG_LEVEL,

  ipsi: {
    baseUrl: process.env.IPSI_BASE_URL,
  },

  featureToggles: {
    isPriceBreakdownEnabled: process.env.PRICE_BREAKDOWN_ENABLED === "true",
    isNonPosEnabled: process.env.NONPOS_ENABLED === "true",
  },

  datadog: {
    appId: process.env.DATADOG_RUM_APP_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    sampleRate: process.env.DATADOG_SAMPLE_RATE,
    trackInteractions: process.env.DATADOG_TRACK_INTERACTIONS,
  },

  merchantOktaConfig: {
    clientId: process.env.MERCHANT_OKTA_CLIENT_ID,
    issuer: process.env.MERCHANT_OKTA_ISSUER,
    redirectUri: `${process.env.APP_URL}/merchant/login/callback`,
    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    disableHttpsCheck: false,
    useInteractionCodeFlow: true,
    useClassicEngine: false,
    services: {
      autoRenew: true,
    },
  },

  customerOktaConfig: {
    clientId: process.env.CUSTOMER_OKTA_CLIENT_ID,
    issuer: process.env.CUSTOMER_OKTA_ISSUER,
    redirectUri: `${process.env.APP_URL}/wallet/checkout/login/callback`,
    scopes: ["customer", "openid", "profile", "email", "offline_access"],
    pkce: true,
    disableHttpsCheck: false,
    useInteractionCodeFlow: true,
    useClassicEngine: true,
  },
  threatMetrixConfig: {
    orgId: process.env.THREATMATRIX_ORG_ID,
  },

  resourceBaseUrl: process.env.RESOURCE_BASE_URL,
  linkingServiceBaseUrl: process.env.LINKING_SERVICE_BASE_URL,
  applyGemUrl: process.env.APPLY_GEM_URL
};
